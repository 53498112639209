import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    "& > *": {
      textTransform: "none",
      marginRight: theme.spacing(2),
      color: theme.palette.text.primary,
    },
  },
}));

export default function NavMenu(): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <Button>{t("AboutUs")}</Button>
      <Button>{t("Tutorials")}</Button>
      <Button>{t("Blog")}</Button>
      <Button>{t("FAQ")}</Button>
    </div>
  );
}
