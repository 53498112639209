import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import { useSelector } from "react-redux";
import textureImage from "../../assets/images/texture50.png";
import { AppState } from "../../store/state";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingTop: 50,
    [theme.breakpoints.down("sm")]: {
      paddingTop: 40,
    },
    backgroundColor: theme.palette.background.paper,
  },
  content: {
    width: "100%",
  },
  video: {
    width: "100%",
    zIndex: 2,
    borderRadius: theme.shape.borderRadius * 3.75,
    boxShadow: theme.shadow.animationVideo,
    "&:focus": {
      outline: "none",
    },
  },
  textureBottom: {
    flip: false,
    marginTop: -154,
    marginLeft: theme.direction === "ltr" ? 76 : 0,
    marginRight: theme.direction === "ltr" ? 0 : -76,
    width: "100%",
    height: 223,
    zIndex: 1,
    backgroundImage: `url('${textureImage}')`,
    backgroundPosition: "right",
    backgroundRepeat: "no-repeat",
  },
}));

export default function Video(): JSX.Element {
  const classes = useStyles();
  const { userLanguage } = useSelector((state: AppState) => {
    return {
      userLanguage: state.userLanguage,
    };
  });
  return (
    <div className={classes.root}>
      <Container maxWidth="sm">
        <Grid
          container
          direction="row-reverse"
          justify="center"
          alignItems="center"
        >
          <Grid item>
            <div className={classes.content}>
              <video
                controls
                className={classes.video}
                preload="auto"
                playsInline
              >
                <track kind="captions" />
                <source
                  src={`/assets/${userLanguage.code}/animation.mp4#t=0.1`}
                  type="video/mp4"
                />
              </video>
            </div>
            <Hidden smDown>
              <div className={classes.textureBottom} />
            </Hidden>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
