import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { ValidLanguageCodes } from "@rambody/commons/lib/constants";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import logo from "../../assets/images/logo-rambody-text-white.png";
import AppSettings from "../../constants/AppSettings";
import { AppState } from "../../store/state";
import SocialLinks from "./SocialLinks";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.footer.background.main,
    paddingTop: theme.spacing(4),
    color: theme.footer.text.primary,
  },
  logo: {
    width: theme.spacing(20),
    height: theme.spacing(5),
  },
  footerText: {
    color: theme.footer.text.primary,
    opacity: 0.69,
  },
  footerContact: {
    color: theme.palette.primary.main,
    textDecoration: "none",
    fontSize: theme.typography.pxToRem(16),
  },
  listSubheader: {
    color: theme.footer.text.primary,
    fontSize: theme.typography.pxToRem(18),
  },
  copyRight: {
    backgroundColor: theme.footer.background.dark,
  },
}));

export default function AppFooter(): JSX.Element {
  const classes = useStyles();
  const socialInfo = {
    is_instagram_verified: true,
    instagram: "rambody.app",
    is_facebook_verified: false,
    facebook_url: "rambody.ca",
    is_telegram_verified: false,
    telegram: "Rambody",
    is_website_verified: true,
    website_url: "RambodyApp",
  };
  const { t } = useTranslation();
  const { userLanguage } = useSelector((state: AppState) => {
    return {
      userLanguage: state.userLanguage,
    };
  });

  return (
    <div className={classes.root}>
      <Container maxWidth="md">
        <Grid container>
          <Grid item>
            <img className={classes.logo} src={logo} alt="Logo" />
          </Grid>
        </Grid>
        <Grid container direction="row" justify="space-between">
          <Grid item xs={12} md={4}>
            <p className={classes.footerText}>{t("CompanyDescription")}</p>
          </Grid>
        </Grid>
        <Grid container direction="row" justify="space-between">
          <Grid item xs={12} md={4}>
            <p>
              <a
                className={classes.footerContact}
                href={AppSettings.Links.Website}
              >
                www.Rambody.com
              </a>
            </p>
            <p>
              <a
                className={classes.footerContact}
                href={`mailto:${AppSettings.Links.Email}`}
              >
                info@rambody.com
              </a>
            </p>
          </Grid>
          <Grid item xs={12} md={4}>
            <p>
              <a className={classes.footerText} href={AppSettings.Links.FAQ}>
                {t("FAQ")}
              </a>
            </p>
            <p>
              <a
                className={classes.footerText}
                href={
                  AppSettings.Links.Blog[
                    userLanguage.code as ValidLanguageCodes
                  ]
                }
              >
                {t("Blog")}
              </a>
            </p>
          </Grid>
        </Grid>
      </Container>
      <div className={classes.copyRight}>
        <Container maxWidth="md">
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            spacing={2}
          >
            <Grid item>
              <p className={classes.footerText}>
                {t("AllRightsReserved")}
                <br />
                {t("CompanyName")}
              </p>
            </Grid>
            <Grid item>
              <SocialLinks socialInfo={socialInfo} showLocationIcon={false} />
            </Grid>
          </Grid>
        </Container>
      </div>
    </div>
  );
}
