import { Theme } from "@material-ui/core/styles";
import { Language } from "../models/Language";
import i18n from "../plugins/i18next";
import AppSettings from "../constants/AppSettings";
import { getTheme } from "../theme";

export interface AppState {
  languages: Language[];
  userLanguage: Language;
  theme: Theme;
}

let defaultUserLanguage: Language = AppSettings.DefaultLanguages[0];
if (i18n.language) {
  defaultUserLanguage =
    AppSettings.DefaultLanguages.find((x) => x.code === i18n.language) ||
    AppSettings.DefaultLanguages[0];
}
export const initialState: AppState = {
  languages: AppSettings.DefaultLanguages,
  userLanguage: defaultUserLanguage,
  theme: getTheme(defaultUserLanguage.code),
};
