import en from "./en";
import fa from "./fa";
import ar from "./ar";
import { LanguageCodes } from "../../constants/enums";

export default {
  [LanguageCodes.English]: en,
  [LanguageCodes.Persian]: fa,
  [LanguageCodes.Arabic]: ar,
};
