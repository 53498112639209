import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
import { combinePath } from "@rambody/commons/lib/extentions/path-helper";
import { formatString } from "@rambody/commons/lib/extentions/string-helper";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import textureImage from "../../assets/images/texture.png";
import tutorialVideos from "../../assets/tutorial-videos";
import AppSettings from "../../constants/AppSettings";
import { TutorialVideo } from "../../models/TutorialVideo";
import { useClampedIsInViewport } from "../../plugins/use-clamped-is-in-viewport";
import { AppState } from "../../store/state";
import TutorialVideoDialog from "./TutorialVideoDialog";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingTop: theme.spacing(9),
    paddingBottom: theme.spacing(9),
    backgroundColor: theme.header.background.main,
    overflow: "hidden",
  },
  slideContainer: {
    padding: theme.spacing(4),
    position: "relative",
    "&:after": {
      zIndex: 10,
      pointerEvents: "none",
      content: "''",
      position: "absolute",
      top: theme.spacing(0),
      left: theme.spacing(0),
      right: theme.spacing(0),
      bottom: theme.spacing(0),
      display: "block",
      background: theme.gradient.boxLeftHide98Gray,
    },
  },
  slide: {
    width: theme.spacing(40),
    height: theme.spacing(50),
    borderRadius: theme.shape.borderRadius * 3,
    position: "relative",
    boxShadow: theme.shadow.tutorialVideoItem,
    "& >img": {
      borderRadius: theme.shape.borderRadius * 3,
      width: "100%",
      height: "100%",
    },
    "& >.slideText": {
      borderRadius: theme.shape.borderRadius * 3,
      position: "absolute",
      width: "100%",
      height: "50%",
      bottom: theme.spacing(0),
      left: theme.spacing(0),
      right: theme.spacing(0),
      backgroundColor: theme.palette.background.paper,
      "& >.title": {
        fontSize: theme.typography.pxToRem(20.8),
        margin: theme.spacing(4, 3, 3, 3),
        flexGrow: 1,
        width: theme.spacing(34),
        overflow: "hidden",
        maxHeight: theme.spacing(7.75),
      },
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "flex-end",
    },
  },
  header: {
    textAlign: "center",
  },
  title: {
    fontWeight: theme.title.fontWeight,
    color: theme.palette.text.primary,
    fontSize: theme.title.fontSize,
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.title.downSm.fontSize,
    },
  },
  description: {
    maxWidth: theme.spacing(62),
    marginLeft: "auto",
    marginRight: "auto",
  },
  pagination: {
    width: "100%",
    height: theme.spacing(1),
  },
  sliderWrapper: {
    marginRight: theme.spacing(0),
    marginLeft: "auto",
    paddingRight: theme.spacing(0),
    paddingTop: theme.spacing(5),
    position: "relative",
    [theme.breakpoints.up("sm")]: {
      maxWidth: `calc(100vw - ((100vw - ${theme.breakpoints.values.md}px) / 2))`,
    },
  },
  textureTop: {
    position: "absolute",
    width: theme.spacing(49),
    height: theme.spacing(29),
    top: theme.spacing(0),
    left: theme.spacing(-5.5),
    [theme.breakpoints.down("sm")]: {
      left: theme.direction === "ltr" ? theme.spacing(-5.5) : theme.spacing(0),
    },
    zIndex: 1,
    transform: theme.direction === "ltr" ? "rotate(180deg)" : "rotate(0deg)",
    opacity: 0.7,
  },
  play: {
    justifySelf: "flex-end",
  },
  playIcon: {
    fontSize: theme.typography.pxToRem(40),
  },
  video: {
    width: "100%",
    borderRadius: theme.shape.borderRadius * 3.75,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

interface Props {
  trainerId: string;
}

export default function TutorialVideos(props: Props): JSX.Element | null {
  const { trainerId } = props;
  const classes = useStyles();
  const { userLanguage } = useSelector((state: AppState) => {
    return {
      userLanguage: state.userLanguage,
      theme: state.theme,
    };
  });
  const [swiperInstance, setSwiperInstance] = useState<
    Parameters<Required<Swiper>["onSwiper"]>[0] | null
  >(null);
  const { t } = useTranslation();
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState<TutorialVideo | null>(
    null
  );

  const ref = useRef(null);
  const [isInViewport, targetRef] = useClampedIsInViewport({
    target: ref,
  });

  useEffect(() => {
    return (): void => {
      if (swiperInstance) {
        swiperInstance.destroy();
      }
    };
    // "react-hooks/exhaustive-deps" is disabled because dependency list must be empty to only run the effect on component unmout.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (swiperInstance && isInViewport) {
      swiperInstance.autoplay.start();
    } else if (swiperInstance && !isInViewport) {
      swiperInstance.autoplay.stop();
    }
  }, [isInViewport, swiperInstance]);

  const closeDialog = (): void => {
    setDialogIsOpen(false);
  };
  const openDialog = (tutorialVideo: TutorialVideo) => {
    return (): void => {
      setSelectedVideo(tutorialVideo);
      setDialogIsOpen(true);
    };
  };

  if (!tutorialVideos[userLanguage.code].length) {
    return null;
  }

  return (
    <div className={classes.root} ref={targetRef}>
      <Container maxWidth="md">
        <Grid container>
          <Grid item xs={12} className={classes.header}>
            <Typography
              variant="h4"
              className={`${classes.title} ${
                isInViewport ? "animate__animated animate__fadeInLeft" : "hide"
              }`}
            >
              {t("TutorialVideo")}
            </Typography>
            <p
              className={`${classes.description} ${
                isInViewport ? "animate__animated animate__fadeInRight" : "hide"
              }`}
            >
              {t("TutorialVideoDescription")}
            </p>
          </Grid>
        </Grid>
      </Container>
      <div className={classes.sliderWrapper}>
        <img src={textureImage} alt="" className={classes.textureTop} />
        <Swiper
          lazy
          grabCursor
          slidesPerView="auto"
          spaceBetween={50}
          scrollbar={{
            el: `.${classes.pagination}`,
            hide: false,
          }}
          className={classes.slideContainer}
          onSwiper={setSwiperInstance}
          autoplay={{
            delay: AppSettings.SlidesAutoPlayDelay,
            disableOnInteraction: true,
          }}
        >
          {tutorialVideos[userLanguage.code].map((tutorialVideo) => {
            return (
              <SwiperSlide key={tutorialVideo.code} className={classes.slide}>
                <img
                  src={
                    tutorialVideo.filePath
                      ? combinePath(
                          tutorialVideo.filePath,
                          tutorialVideo.thumbFileName
                        )
                      : combinePath(
                          formatString(
                            AppSettings.Paths.TutorialVideosFilesPath,
                            userLanguage.code
                          ),
                          tutorialVideo.thumbFileName
                        )
                  }
                  alt={tutorialVideo.title}
                />
                <div className="slideText">
                  <h4 className="title">{tutorialVideo.title}</h4>
                  <IconButton
                    className={classes.play}
                    aria-label="play"
                    color="primary"
                    onClick={openDialog(tutorialVideo)}
                  >
                    <PlayCircleFilledIcon className={classes.playIcon} />
                  </IconButton>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
        <div className={classes.pagination} />
      </div>
      <TutorialVideoDialog
        tutorialVideo={selectedVideo}
        open={dialogIsOpen}
        onClose={closeDialog}
        trainerId={trainerId}
      />
    </div>
  );
}
