import React from "react";
import { TransitionProps } from "@material-ui/core/transitions/transition.d";
import Slide from "@material-ui/core/Slide";

const SlideTransition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return (
    <Slide
      direction="up"
      ref={ref}
      addEndListener={props.addEndListener}
      appear={props.appear}
      enter={props.enter}
      exit={props.exit}
      in={props.in}
      mountOnEnter={props.mountOnEnter}
      onEnter={props.onEnter}
      onEntered={props.onEntered}
      onExit={props.onExit}
      onExited={props.onExited}
      onExiting={props.onExiting}
      style={props.style}
      timeout={props.timeout}
      unmountOnExit={props.unmountOnExit}
    >
      {props.children}
    </Slide>
  );
});

export default SlideTransition;
