import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { combinePath } from "@rambody/commons/lib/extentions/path-helper";
import { formatString } from "@rambody/commons/lib/extentions/string-helper";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import AppSettings from "../../constants/AppSettings";
import { TutorialVideo } from "../../models/TutorialVideo";
import { AppState } from "../../store/state";
import SlideTransition from "../Transition/Slide";

const useStyles = makeStyles((theme: Theme) => ({
  video: {
    maxWidth: "100%",
    maxHeight: "100%",
    "&:focus": {
      outline: "none",
    },
  },
  dialogTitle: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    zIndex: 10,
    "&.fullscreen": {
      "&:before": {
        pointerEvents: "none",
        content: "''",
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        display: "block",
        background: theme.gradient.boxBlackToBottom,
        zIndex: -1,
      },
    },
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    "&.fullscreen": {
      color: theme.palette.common.white,
    },
    color: theme.palette.grey[500],
  },
  title: {
    textAlign: "center",
    "&.fullscreen": {
      color: theme.palette.common.white,
    },
  },
  description: {
    whiteSpace: "pre-wrap",
    textAlign: "justify",
  },
  videoGrid: {
    textAlign: "center",
  },
  dialogPaper: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      minHeight: "95vh",
      maxHeight: "95vh",
      minWidth: "95vw",
      maxWidth: "95vw",
    },
  },
  dialogActions: {
    [theme.breakpoints.up("md")]: {
      justifyContent: "flex-start",
    },
  },
}));

interface Props {
  open: boolean;
  onClose: () => void;
  tutorialVideo: TutorialVideo | null;
  trainerId: string;
}

export default function TutorialVideoDialog(props: Props): JSX.Element {
  const classes = useStyles();
  const { open, tutorialVideo, onClose, trainerId } = props;
  const { userLanguage, theme } = useSelector((state: AppState) => {
    return {
      userLanguage: state.userLanguage,
      theme: state.theme,
    };
  });

  const { t } = useTranslation();
  const fullScreen = !tutorialVideo?.description;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      keepMounted={false}
      fullScreen={fullScreen}
      TransitionComponent={SlideTransition}
      fullWidth
      maxWidth="md"
      classes={{ paper: classes.dialogPaper }}
    >
      <DialogTitle
        className={`${classes.dialogTitle} ${fullScreen ? "fullscreen" : ""}`}
        disableTypography
      >
        {fullScreen && (
          <h3
            dir={theme.direction}
            className={`${classes.title} ${fullScreen ? "fullscreen" : ""}`}
          >
            {tutorialVideo?.title}
          </h3>
        )}
      </DialogTitle>
      {fullScreen && tutorialVideo && (
        <video
          controls
          className={`${classes.video} ${fullScreen ? "fullscreen" : ""}`}
          preload="metadata"
          playsInline
          autoPlay
        >
          <track kind="captions" />
          <source
            src={
              tutorialVideo?.filePath
                ? `${combinePath(
                    tutorialVideo?.filePath,
                    tutorialVideo?.fileName
                  )}#t=0.1`
                : `${formatString(
                    AppSettings.Paths.TutorialVideosFilesPath,
                    userLanguage.code
                  )}/${tutorialVideo?.fileName}#t=0.1`
            }
            type="video/mp4"
          />
        </video>
      )}
      {!fullScreen && (
        <DialogContent dir={theme.direction}>
          <Hidden mdDown>
            <Grid container spacing={2} direction="row" wrap="nowrap">
              <Grid item xs={6}>
                <Grid
                  container
                  spacing={2}
                  direction="column"
                  style={{ minHeight: "100%" }}
                  wrap="nowrap"
                >
                  <Grid item style={{ flexGrow: 1, paddingTop: 30 }}>
                    <div className={classes.title}>
                      <img
                        width="44"
                        height="44"
                        src={
                          tutorialVideo?.filePath
                            ? combinePath(
                                tutorialVideo?.filePath || "",
                                tutorialVideo?.icon || ""
                              )
                            : combinePath(
                                formatString(
                                  AppSettings.Paths.TutorialVideosFilesPath,
                                  userLanguage.code
                                ),
                                tutorialVideo?.icon || ""
                              )
                        }
                        alt=""
                      />
                      <h2>{tutorialVideo?.title}</h2>
                    </div>
                    <p className={classes.description}>
                      {formatString(
                        tutorialVideo?.description || "",
                        trainerId
                      )}
                    </p>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      autoFocus
                      onClick={onClose}
                      color="primary"
                    >
                      {t("Close")}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6} style={{ display: "flex" }}>
                {tutorialVideo && (
                  <video
                    controls
                    className={`${classes.video} ${
                      fullScreen ? "fullscreen" : ""
                    }`}
                    preload="metadata"
                    playsInline
                    autoPlay
                  >
                    <track kind="captions" />
                    <source
                      src={
                        tutorialVideo?.filePath
                          ? `${combinePath(
                              tutorialVideo?.filePath,
                              tutorialVideo?.fileName
                            )}#t=0.1`
                          : `${combinePath(
                              formatString(
                                AppSettings.Paths.TutorialVideosFilesPath,
                                userLanguage.code
                              ),
                              tutorialVideo?.fileName
                            )}#t=0.1`
                      }
                      type="video/mp4"
                    />
                  </video>
                )}
              </Grid>
            </Grid>
          </Hidden>
          <Hidden lgUp>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} style={{ paddingTop: 30 }}>
                <div className={classes.title}>
                  <img
                    width="44"
                    height="44"
                    src={
                      tutorialVideo?.filePath
                        ? combinePath(
                            tutorialVideo?.filePath || "",
                            tutorialVideo?.icon || ""
                          )
                        : combinePath(
                            formatString(
                              AppSettings.Paths.TutorialVideosFilesPath,
                              userLanguage.code
                            ),
                            tutorialVideo?.icon || ""
                          )
                    }
                    alt=""
                  />
                  <h2>{tutorialVideo?.title}</h2>
                </div>
                <p className={classes.description}>
                  {formatString(tutorialVideo?.description || "", trainerId)}
                </p>
              </Grid>
              <Grid item xs={12} md={6} className={classes.videoGrid}>
                {tutorialVideo && (
                  <video
                    controls
                    className={`${classes.video} ${
                      fullScreen ? "fullscreen" : ""
                    }`}
                    preload="metadata"
                    playsInline
                    autoPlay
                  >
                    <track kind="captions" />
                    <source
                      src={
                        tutorialVideo?.filePath
                          ? `${combinePath(
                              tutorialVideo?.filePath,
                              tutorialVideo?.fileName
                            )}#t=0.1`
                          : `${combinePath(
                              formatString(
                                AppSettings.Paths.TutorialVideosFilesPath,
                                userLanguage.code
                              ),
                              tutorialVideo?.fileName
                            )}#t=0.1`
                      }
                      type="video/mp4"
                    />
                  </video>
                )}
              </Grid>
            </Grid>
          </Hidden>
        </DialogContent>
      )}
      {!fullScreen && (
        <Hidden lgUp>
          <DialogActions>
            <Button
              variant="contained"
              autoFocus
              onClick={onClose}
              color="primary"
            >
              {t("Close")}
            </Button>
          </DialogActions>
        </Hidden>
      )}
    </Dialog>
  );
}

TutorialVideoDialog.defaultProps = {
  tutorialVideo: null,
} as Partial<Props>;
