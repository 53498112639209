import IconButton from "@material-ui/core/IconButton";
import { makeStyles, Theme } from "@material-ui/core/styles";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import TelegramIcon from "@material-ui/icons/Telegram";
import TwitterIcon from "@material-ui/icons/Twitter";
import React from "react";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    opacity: 0.8,
    "& > *": {
      textTransform: "none",
      marginRight: 2,
    },
  },
  icon: {
    fontSize: theme.typography.pxToRem(18),
  },
  locationIcon: {
    fontSize: theme.typography.pxToRem(25),
  },
  button: {
    padding: theme.spacing(0.5),
    backgroundColor: theme.socialLink.background.main,
  },
  locationButton: {
    padding: theme.spacing(0.5),
  },
}));

const openInstagram = (instagram: string) => {
  return (): void => {
    window.open(
      instagram.startsWith("http")
        ? instagram
        : `https://www.instagram.com/${instagram}/`,
      "_blank"
    );
  };
};

const openFacebook = (facebook: string) => {
  return (): void => {
    window.open(
      facebook.includes("/")
        ? facebook
        : `https://www.facebook.com/pages/${facebook}/`,
      "_blank"
    );
  };
};

const openTelegram = (telegram: string) => {
  return (): void => {
    window.open(
      telegram.startsWith("http") ? telegram : `https://t.me/${telegram}/`,
      "_blank"
    );
  };
};

const openTwitter = (twitter: string) => {
  return (): void => {
    window.open(
      twitter.startsWith("http") ? twitter : `https://twitter.com/${twitter}/`,
      "_blank"
    );
  };
};

interface ComponentProps {
  showLocationIcon?: boolean;
  socialInfo?: {
    telegram_id?: string;
    whatsapp_number?: string;
    twitter_url?: string;
    linkedin_url?: string;
    instagram_url?: string;
    facebook_url?: string;
  };
}

export default function SocialLinks(props: ComponentProps): JSX.Element {
  const classes = useStyles();
  const { showLocationIcon, socialInfo } = props;
  return (
    <div className={classes.root}>
      {showLocationIcon && (
        <IconButton className={classes.locationButton} color="primary">
          <LocationOnIcon className={classes.locationIcon} />
        </IconButton>
      )}
      {socialInfo?.facebook_url && (
        <IconButton
          onClick={openFacebook(socialInfo.facebook_url)}
          className={classes.button}
          color="default"
        >
          <FacebookIcon className={classes.icon} />
        </IconButton>
      )}
      {socialInfo?.instagram_url && (
        <IconButton
          onClick={openInstagram(socialInfo.instagram_url)}
          className={classes.button}
          color="default"
        >
          <InstagramIcon className={classes.icon} />
        </IconButton>
      )}
      {socialInfo?.telegram_id && (
        <IconButton
          onClick={openTelegram(socialInfo.telegram_id)}
          className={classes.button}
          color="default"
        >
          <TelegramIcon className={classes.icon} />
        </IconButton>
      )}
      {socialInfo?.twitter_url && (
        <IconButton
          onClick={openTwitter(socialInfo.twitter_url)}
          className={classes.button}
          color="default"
        >
          <TwitterIcon className={classes.icon} />
        </IconButton>
      )}
    </div>
  );
}

SocialLinks.defaultProps = {
  showLocationIcon: false,
};
