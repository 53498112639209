import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import { Theme, makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    fontSize: theme.typography.pxToRem(13),
    color: theme.palette.text.primary,
    textDecoration: "underline",
    textTransform: "uppercase",
  },
}));

interface ComponentProps {
  onStateChanged?: (state: boolean) => void;
}

export default function ReadMore(props: ComponentProps): JSX.Element {
  const classes = useStyles();
  const { onStateChanged } = props;
  const [expanded, setExpanded] = useState(false);
  const { t } = useTranslation();

  function handleClick(): void {
    setExpanded(!expanded);
    if (onStateChanged) {
      onStateChanged(!expanded);
    }
  }

  return (
    <Button onClick={handleClick} className={classes.root}>
      {expanded ? t("Less") : t("ReadMore")}
    </Button>
  );
}

ReadMore.defaultProps = {
  onStateChanged: undefined,
};
