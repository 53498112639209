import { getServiceUri, GraphQLServices } from "@rambody/commons/lib/services";
import { Language } from "../models/Language";
import { LanguageCodes } from "./enums";

function getBaseUrl(): string {
  return (
    (window as Window & typeof globalThis & { servicesEndpoint: string })
      .servicesEndpoint || "http://localhost:7071"
  );
}

const AppSettings = {
  GRAPHQL_SERVER: getServiceUri(getBaseUrl(), GraphQLServices.Trainer),
  RB_ERROR: "RB_ERROR",
  DefaultLanguages: [
    {
      code: LanguageCodes.English,
      title: "English",
      direction: "ltr",
      abbreviation: "En",
    },
    {
      code: LanguageCodes.Persian,
      title: "فارسی",
      direction: "rtl",
      abbreviation: "فا",
    },
    {
      code: LanguageCodes.Arabic,
      title: "العربية",
      direction: "rtl",
      abbreviation: "العربية",
    },
  ] as Language[],
  ShowNavMenu: false,
  Paths: {
    ScreenshotsFilesPath: "/assets/{0}/screenshots",
    ScreenshotsCommonFilesPath: "/assets/common/screenshots",
    TutorialVideosFilesPath: "/assets/{0}/tutorial-videos",
    TutorialVideosCommonFilesPath: "/assets/common/tutorial-videos",
  },
  AppName: "trainer-webapp-client",
  ConfigProviderApi: getServiceUri(
    getBaseUrl(),
    GraphQLServices.ConfigProvider
  ),
  SlidesAutoPlayDelay: 5000,
  SubItemsShowDelay: 200,
  Links: {
    FAQ: "https://www.rambody.ca/faq",
    Blog: {
      [LanguageCodes.English]:
        "https://www.rambody.ca/blog/categories/englishblogs",
      [LanguageCodes.Persian]: "https://instagram.com/rambody.fa",
      [LanguageCodes.Arabic]:
        "https://www.rambody.ca/blog/categories/arabicblogs",
      [LanguageCodes.Turkish]:
        "https://www.rambody.ca/blog/categories/turkishblogs",
    },
    Email: "info@rambody.com",
    SupportEmail: "support@rambody.com",
    Website: "https://www.rambody.ca/",
    GooglePlayStoreLink:
      "https://play.google.com/store/apps/details?id=co.rambody",
    AppStoreLink: "https://apps.apple.com/app/rambody/id1300350064",
    AndroidDirectDownloadLink:
      "https://trainer.rambody.com:1343/ftp/pub/app/rambody.apk",
    OpenAppLink: "rambody://trainer_profile/{0}/",
  },
};
export default AppSettings;
