// A react hook to use the IntersectionObserver declaratively in the React app for the purposes of finding out if an element is in a given viewport.

import { useEffect, useState } from "react";
import useIsInViewport, { CallbackRef, HookOptions } from "use-is-in-viewport";

export function useClampedIsInViewport(
  options?: HookOptions
): [boolean | null, CallbackRef, CallbackRef] {
  const [isInViewport, callbackRef1, callbackRef2] = useIsInViewport({
    threshold: 20,
    ...options,
  });
  const [wasInViewportAtleastOnce, setWasInViewportAtleastOnce] =
    useState(isInViewport);

  useEffect(() => {
    if (!wasInViewportAtleastOnce && isInViewport) {
      setWasInViewportAtleastOnce(isInViewport);
    }
  }, [isInViewport, wasInViewportAtleastOnce]);

  return [wasInViewportAtleastOnce, callbackRef1, callbackRef2];
}
