import { InMemoryCache, NormalizedCacheObject } from "apollo-cache-inmemory";
import { ApolloClient } from "apollo-client";
import { onError } from "apollo-link-error";
import { HttpLink } from "apollo-link-http";
import { ServerError } from "apollo-link-http-common";
import AppSettings from "../constants/AppSettings";

const cache = new InMemoryCache();
const httpLink = new HttpLink({
  uri: AppSettings.GRAPHQL_SERVER,
  headers: {
    "Content-Type": "application/graphql",
    "Access-Control-Allow-Origin": `${window.location.protocol}//${window.location.host}`,
  },
});

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  let errorCode = 500;
  let message = "An error occurred while processing your request.";
  let operationId = "";

  if (graphQLErrors && graphQLErrors.length) {
    const {
      response: { headers },
    } = operation.getContext() as {
      response: { headers?: { get: (key: string) => string | undefined } };
    };
    if (headers) operationId = headers.get("operation-id") || "";

    if (graphQLErrors[0].extensions?.code === AppSettings.RB_ERROR) {
      message = graphQLErrors[0].message;
    }
  }
  if (networkError) {
    errorCode = (networkError as ServerError).statusCode || 500;
  }
  window.location.href = `/error/${errorCode}/${encodeURI(message)}/${encodeURI(
    operationId
  )}`;
});

const link = errorLink.concat(httpLink);
const client: ApolloClient<NormalizedCacheObject> = new ApolloClient({
  cache,
  link,
});

export default client;
