import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import React from "react";
import AppBar from "../../Common/AppBar";
import SocialLinks from "../../Common/SocialLinks";
import ProfileImage from "./ProfileImage";
import ProfileName from "./ProfileName";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.header.background.main,
    [theme.breakpoints.down("sm")]: {
      backgroundSize: "cover",
      backgroundPosition: "center",
      zIndex: 1,
      position: "relative",
      "&:before": {
        zIndex: 0,
        pointerEvents: "none",
        content: "''",
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        display: "block",
        backgroundColor: theme.palette.common.black,
        opacity: 0.35,
      },
    },
  },
  info: {
    zIndex: 1,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  socialLinks: {
    padding: theme.spacing(0, 6, 2, 6),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0, 6, 0, 1),
    },
  },
  profileName: {
    zIndex: 1,
  },
  infoGrid: {
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1,
    [theme.breakpoints.down("sm")]: {
      minHeight: theme.spacing(51),
      justifyContent: "flex-end",
      alignItems: "flex-end",
      paddingBottom: theme.spacing(1),
    },
  },
}));

interface Props {
  fullName: string | null;
  trainerId: string | null;
  photoUrl: string | null;
  socialInfo?: {
    telegram_id?: string;
    whatsapp_number?: string;
    twitter_url?: string;
    linkedin_url?: string;
    instagram_url?: string;
    facebook_url?: string;
  };
}

export default function ProfileInfo({
  fullName,
  trainerId,
  photoUrl,
  socialInfo,
}: Props): JSX.Element {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <div
      className={classes.root}
      style={
        matches
          ? {
              backgroundImage: `url("${photoUrl}")`,
            }
          : {}
      }
    >
      <AppBar />
      <div className={classes.info}>
        <Container maxWidth="md">
          <Grid container direction="row-reverse" className={classes.infoGrid}>
            <Hidden smDown>
              <Grid item xs={12} lg={6}>
                <ProfileImage photoUrl={photoUrl} />
              </Grid>
            </Hidden>
            <Grid item xs={12} lg={6} className={classes.profileName}>
              <ProfileName fullName={fullName} trainerId={trainerId} />
            </Grid>
          </Grid>
        </Container>
        <div className={classes.socialLinks}>
          <SocialLinks socialInfo={socialInfo} />
        </div>
      </div>
    </div>
  );
}
