import en from "./en";
import fa from "./fa";
import ar from "./ar";
import { LanguageCodes } from "../../constants/enums";
import { Screenshot } from "../../models/Screenshot";

const screenshots: { [languageCode: string]: Screenshot[] } = {
  [LanguageCodes.English]: en,
  [LanguageCodes.Persian]: fa,
  [LanguageCodes.Arabic]: ar,
};

export default screenshots;
