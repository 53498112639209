import { Screenshot } from "../../models/Screenshot";
import AppSettings from "../../constants/AppSettings";

const screenshots: Screenshot[] = [
  {
    code: 1,
    title: "تمارين متحركة",
    description:
      "يوجد أكثر من 1500 تمرين تدريبي في رمبادي، وكمدرب، سأصمم لك و أرسل لك خطة تدريب خاصة بالاختيار من بين هذه التمارين. لاحظ أن هذه الخطط لم يتم إعدادها مسبقًا وسأقوم بتصميم و تقديم الخطة بناءً على احتياجاتك و بعد الوقت المحدد",
    fileName: "screenshot1.gif",
    filePath: AppSettings.Paths.ScreenshotsCommonFilesPath,
  },
  {
    code: 2,
    title: "العضلات المشاركة",
    description:
      "في خطة التمرين المرسلة، أحدد عضلاتك التي تشارك في كل تمرين. باستخدام هذه الميزة، يمكنك التركيز على العضلات المستهدفة أثناء القيام بذلك.",
    fileName: "screenshot2.jpg",
    filePath: AppSettings.Paths.ScreenshotsCommonFilesPath,
  },
  {
    code: 3,
    title: "خطة تدريب بدون معدات",
    description:
      "إذا أشرت لي أنه ليس لديك معدات متوفرة، حسب مجموعة التمارين المتنوعة، سأقوم بتصميم خطة خاصة دون الحاجة إلى أي معدات وبمساعدة وزن جسمك.",
    fileName: "screenshot3.gif",
    filePath: AppSettings.Paths.ScreenshotsCommonFilesPath,
  },
  {
    code: 4,
    title: "مخطط التقدم",
    description:
      "بإدخال وزنك وحجمك، يمكنك رؤية التغييرات على الرسم البياني بناءاً على الرسم البياني و سرعة تغييراتك و من خلال تحليل حالتك البدنية، يمكنني تغيير خطتك للوصول إلى هدفك بشكل أسرع وأفضل.",
    fileName: "screenshot4.jpg",
    filePath: AppSettings.Paths.ScreenshotsCommonFilesPath,
  },
];

export default screenshots;
