import AppSettings from "../constants/AppSettings";
import axios from "../plugins/axios";

export async function saveConfig(
  configType: string,
  data: Record<string, unknown>
): Promise<void> {
  await axios.post(
    AppSettings.ConfigProviderApi,
    {
      configType,
      appName: AppSettings.AppName,
      data,
    },
    {
      headers: {
        "Access-Control-Allow-Origin": `${window.location.protocol}//${window.location.host}`,
      },
    }
  );
}
