import { createMuiTheme, Theme, ThemeOptions } from "@material-ui/core/styles";
import { LanguageCodes } from "./constants/enums";

const defaultTheme: ThemeOptions = {
  direction: "ltr",
  palette: {
    primary: {
      main: "#387CFF",
      dark: "#0069d9",
    },
    text: {
      primary: "#394959",
      secondary: "#031A37",
    },
  },
  header: {
    background: {
      main: "#F4F9FE",
    },
    text: {
      primary: "#FFFFFF",
    },
  },
  footer: {
    background: {
      main: "#394959",
      dark: "#334250",
    },
    text: {
      primary: "#FFFFFF",
    },
  },
  socialLink: {
    background: {
      main: "#dde3ea",
    },
  },
  gradient: {
    textHide:
      "linear-gradient(rgba(255, 255, 255, 0) 85%, rgba(255, 255, 255, 1))",
    boxLeftHide90: "linear-gradient(to left,rgba(255, 255, 255, 0) 90%, #FFF)",
    boxBlackToBottom: "linear-gradient(#000 , transparent)",
    boxLeftHide98Gray:
      "linear-gradient(to left,rgba(255, 255, 255, 0) 98%, #F4F9FE)",
    boxLeftHide98: "linear-gradient(to left,rgba(255, 255, 255, 0) 98%, #FFF)",
    itemBlure: "rgba(213, 220, 226, 0.7)",
  },
  shadow: {
    animationVideo: "8px 37px 42px 0 rgba(56,124,255,0.13)",
    screenshotItem: "0px 0px 13px 2px rgba(57,73,89, 0.2)",
    palnItem: "0 0px 17px 10px rgba(57,73,89,0.2)",
    appIcon: "0 4px 26px 3px rgba(56,124,255,.14)",
    appIconStrong: "0 4px 26px 3px rgba(56,124,255,.20)",
    appDownloadButton: "0 16px 25px 0 rgba(57,73,89,.25)",
    tutorialVideoItem: "0 7px 22px 0 rgba(57,73,89,0.3)",
  },
  myPlans: {
    font: "Oswald",
  },
  profile: {
    name: {
      fontFamily: "Oswald, Heebo, Roboto, Helvetica, Arial, sans-serif",
    },
  },
  title: {
    fontWeight: "bold",
    fontSize: 29,
    downSm: {
      fontSize: 25,
    },
  },
  typography: {
    fontFamily: "Heebo, Roboto, Helvetica, Arial, sans-serif",
    h1: {
      fontFamily: "Heebo, Roboto, Helvetica, Arial, sans-serif",
    },
    h2: {
      fontFamily: "Heebo, Roboto, Helvetica, Arial, sans-serif",
    },
    h3: {
      fontFamily: "Heebo, Roboto, Helvetica, Arial, sans-serif",
    },
    h4: {
      fontFamily: "Heebo, Roboto, Helvetica, Arial, sans-serif",
    },
    h5: {
      fontFamily: "Heebo, Roboto, Helvetica, Arial, sans-serif",
    },
    h6: {
      fontFamily: "Heebo, Roboto, Helvetica, Arial, sans-serif",
    },
    subtitle1: {
      fontFamily: "Heebo, Roboto, Helvetica, Arial, sans-serif",
    },
    subtitle2: {
      fontFamily: "Heebo, Roboto, Helvetica, Arial, sans-serif",
    },
    button: {
      fontFamily: "Heebo, Roboto, Helvetica, Arial, sans-serif",
    },
    caption: {
      fontFamily: "Heebo, Roboto, Helvetica, Arial, sans-serif",
    },
    overline: {
      fontFamily: "Heebo, Roboto, Helvetica, Arial, sans-serif",
    },
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        body: {
          overflowX: "hidden",
          fontFamily: "Heebo, Roboto, Helvetica, Arial, sans-serif",
        },
      },
    },
  },
};

const faTheme: ThemeOptions = {
  ...defaultTheme,
  direction: "rtl",
  myPlans: {
    font: "iranyekan",
  },
  profile: {
    name: {
      fontFamily: "iranyekan, Tahoma, Helvetica, Arial, sans-serif",
    },
  },
  typography: {
    fontFamily: "iranyekan, Tahoma, Helvetica, Arial, sans-serif",
    h1: {
      fontFamily: "iranyekan, Tahoma, Helvetica, Arial, sans-serif",
    },
    h2: {
      fontFamily: "iranyekan, Tahoma, Helvetica, Arial, sans-serif",
    },
    h3: {
      fontFamily: "iranyekan, Tahoma, Helvetica, Arial, sans-serif",
    },
    h4: {
      fontFamily: "iranyekan, Tahoma, Helvetica, Arial, sans-serif",
    },
    h5: {
      fontFamily: "iranyekan, Tahoma, Helvetica, Arial, sans-serif",
    },
    h6: {
      fontFamily: "iranyekan, Tahoma, Helvetica, Arial, sans-serif",
    },
    subtitle1: {
      fontFamily: "iranyekan, Tahoma, Helvetica, Arial, sans-serif",
    },
    subtitle2: {
      fontFamily: "iranyekan, Tahoma, Helvetica, Arial, sans-serif",
    },
    button: {
      fontFamily: "iranyekan, Tahoma, Helvetica, Arial, sans-serif",
    },
    caption: {
      fontFamily: "iranyekan, Tahoma, Helvetica, Arial, sans-serif",
    },
    overline: {
      fontFamily: "iranyekan, Tahoma, Helvetica, Arial, sans-serif",
    },
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        body: {
          overflowX: "hidden",
          fontFamily: "iranyekan, Tahoma, Helvetica, Arial, sans-serif",
        },
      },
    },
  },
};

export function getTheme(language: string): Theme {
  switch (language) {
    case LanguageCodes.Persian:
    case LanguageCodes.Arabic:
      return createMuiTheme(faTheme);
    default:
      return createMuiTheme(defaultTheme);
  }
}

const theme = createMuiTheme(defaultTheme);
export default theme;
