import React, { useState, useRef } from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Collapse from "@kunukn/react-collapse";
import { useTranslation } from "react-i18next";
import ReadMore from "../Common/ReadMore";
import { useClampedIsInViewport } from "../../plugins/use-clamped-is-in-viewport";

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    fontWeight: theme.title.fontWeight,
    color: theme.palette.text.primary,
    fontSize: theme.title.fontSize,
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.title.downSm.fontSize,
    },
  },
  content: {
    margin: theme.spacing(2, 0, 0, 0),
    transition: theme.transitions.create("height", {
      duration: theme.transitions.duration.standard,
      easing: theme.transitions.easing.easeInOut,
    }),
    textAlign: "justify",
  },
  bottomFade: {
    position: "relative",
    "&:after": {
      pointerEvents: "none",
      content: "''",
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      display: "block",
      background: theme.gradient.textHide,
    },
  },
}));

interface Props {
  overview: string | null;
}

interface AboutMeTextProps {
  overview: string | null;
  isInViewport: boolean | null;
}

function AboutMeText({
  overview,
  isInViewport,
}: AboutMeTextProps): JSX.Element {
  return (
    <div
      className={`${
        isInViewport ? "animate__animated animate__fadeInLeft" : "hide"
      }`}
    >
      {overview}
    </div>
  );
}

export default function AboutMe({ overview }: Props): JSX.Element {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const { t } = useTranslation();

  const ref = useRef(null);
  const [isInViewport, targetRef] = useClampedIsInViewport({
    target: ref,
  });

  function handleReadMoreStateChanged(state: boolean): void {
    setExpanded(state);
  }

  // Allow collapse about me text when it's length is greater than 600 characters
  const allowCollapse = overview && overview.length > 600;

  return (
    <div ref={targetRef}>
      <Typography variant="h4" className={classes.title}>
        {t("AboutMeTitle")}
      </Typography>
      {allowCollapse && (
        <Collapse
          isOpen={expanded}
          collapseHeight="190px"
          className={`${classes.content} ${
            !expanded && allowCollapse ? classes.bottomFade : ""
          }`}
        >
          <AboutMeText overview={overview} isInViewport={isInViewport} />
        </Collapse>
      )}
      {!allowCollapse && (
        <div className={classes.content}>
          <AboutMeText overview={overview} isInViewport={isInViewport} />
        </div>
      )}
      {allowCollapse && (
        <ReadMore onStateChanged={handleReadMoreStateChanged} />
      )}
    </div>
  );
}
