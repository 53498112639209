import { Theme } from "@material-ui/core/styles";
import { Dispatch } from "react";
import { Action, Reducer } from "redux";
import { Language } from "../models/Language";
import { AppState, initialState } from "./state";

export interface DispatchAction extends Action<ActionType> {
  payload: Partial<AppState>;
}

export enum ActionType {
  SetLanguage,
  SetTheme,
  SetLanguageAndTheme,
}

export const rootReducer: Reducer<AppState, DispatchAction> = (
  state = initialState,
  action
) => {
  if (action.type === ActionType.SetLanguage) {
    return {
      ...state,
      userLanguage: action.payload.userLanguage || initialState.userLanguage,
    };
  }
  if (action.type === ActionType.SetTheme) {
    return {
      ...state,
      theme: action.payload.theme || initialState.theme,
    };
  }
  if (action.type === ActionType.SetLanguageAndTheme) {
    return {
      ...state,
      theme: action.payload.theme || initialState.theme,
      userLanguage: action.payload.userLanguage || initialState.userLanguage,
    };
  }
  return state;
};

export class RootDispatcher {
  private readonly dispatch: Dispatch<DispatchAction>;

  constructor(dispatch: Dispatch<DispatchAction>) {
    this.dispatch = dispatch;
  }

  setLanguage = (language: Language): void =>
    this.dispatch({
      type: ActionType.SetLanguage,
      payload: { userLanguage: language },
    });

  setLanguageAndTheme = (language: Language, theme: Theme): void => {
    this.dispatch({
      type: ActionType.SetLanguageAndTheme,
      payload: { userLanguage: language, theme },
    });
  };

  setTheme = (theme: Theme): void =>
    this.dispatch({ type: ActionType.SetTheme, payload: { theme } });
}
