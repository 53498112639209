import React, { useState } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import ButtonBase from "@material-ui/core/ButtonBase";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: theme.spacing(3),
    height: theme.spacing(4),
    position: "relative",
    WebkitTransform: "rotate(0deg)",
    MozTransform: "rotate(0deg)",
    OTransform: "rotate(0deg)",
    transform: "rotate(0deg)",
    WebkitTransition: ".5s ease-in-out",
    MozTransition: ".5s ease-in-out",
    OTransition: ".5s ease-in-out",
    transition: ".5s ease-in-out",
    cursor: "pointer",
    "& i": {
      display: "block",
      position: "absolute",
      height: 2,
      width: "100%",
      background: theme.palette.common.white,
      borderRadius: theme.shape.borderRadius,
      opacity: 1,
      left: 0,
      WebkitTransform: "rotate(0deg)",
      MozTransform: "rotate(0deg)",
      OTransform: "rotate(0deg)",
      transform: "rotate(0deg)",
      WebkitTransition: ".25s ease-in-out",
      MozTransition: ".25s ease-in-out",
      OTransition: ".25s ease-in-out",
      transition: ".25s ease-in-out",
    },
    "& i:nth-child(1)": {
      top: 0,
      width: "80%",
      right: 0,
      left: "20%",
    },
    "& i:nth-child(2)": { top: theme.spacing(1) },
    "& i:nth-child(3)": {
      top: theme.spacing(2),
      width: "60%",
      right: 0,
      left: "40%",
    },
  },
  open: {
    "& i:nth-child(1)": {
      width: "100%",
      top: theme.spacing(1),
      WebkitTransform: "rotate(135deg)",
      MozTransform: "rotate(135deg)",
      OTransform: "rotate(135deg)",
      transform: "rotate(135deg)",
      right: 0,
      left: 0,
    },
    "& i:nth-child(2)": { opacity: 0, left: theme.spacing(-7) },
    "& i:nth-child(3)": {
      width: "100%",
      top: theme.spacing(1),
      WebkitTransform: "rotate(-135deg)",
      MozTransform: "rotate(-135deg)",
      OTransform: "rotate(-135deg)",
      transform: "rotate(-135deg)",
      right: 0,
      left: 0,
    },
  },
}));

interface MenuIconProps {
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export default function MenuIcon(props: MenuIconProps): JSX.Element {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const { onClick } = props;
  function handleClick(
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void {
    setIsOpen(!isOpen);
    if (onClick) {
      onClick(e);
    }
  }

  return (
    <ButtonBase
      className={`${classes.root} ${isOpen ? classes.open : ""}`}
      onClick={handleClick}
    >
      <i />
      <i />
      <i />
    </ButtonBase>
  );
}

MenuIcon.defaultProps = {
  onClick: undefined,
};
