import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { ITrainerPublicInfo } from "@rambody/db-schema/lib/user/IUser";
import React from "react";
import AppFooter from "../Common/AppFooter";
import AboutMeAndExpertise from "./AboutMeAndExpertise";
import DownloadApps from "./DownloadApps";
import MyPlans from "./MyPlans";
import ProfileInfo from "./ProfileInfo/ProfileInfo";
import Screenshots from "./Screenshots";
import TutorialVideo from "./TutorialVideo";
import Video from "./Video";
// import TotalSentPlans from "./TotalSentPlans";

const useStyles = makeStyles((theme: Theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
}));

interface Props {
  trainerInfo: ITrainerPublicInfo;
  trainerId: string;
  loading?: boolean;
}

export default function TrainerProfile({
  trainerInfo: {
    fullname: fullName,
    photo_url: photoUrl,
    overview,
    expertise,
    telegram_id,
    whatsapp_number,
    twitter_url,
    linkedin_url,
    instagram_url,
    facebook_url,
    financial_plans: financialPlans,
  },
  trainerId,
  loading,
}: Props): JSX.Element {
  const classes = useStyles();
  return (
    <>
      <Backdrop open={!!loading} className={classes.backdrop}>
        <CircularProgress />
      </Backdrop>
      <ProfileInfo
        fullName={fullName}
        photoUrl={photoUrl}
        trainerId={trainerId}
        socialInfo={{
          telegram_id,
          whatsapp_number,
          twitter_url,
          linkedin_url,
          instagram_url,
          facebook_url,
        }}
      />
      <AboutMeAndExpertise expertise={expertise} overview={overview} />
      {/* <TotalSentPlans totalSentPlans={totalSentPlans} /> */}
      <Video />
      <Screenshots />
      <MyPlans financialPlans={financialPlans} trainerId={trainerId} />
      <DownloadApps />
      <TutorialVideo trainerId={trainerId} />
      <AppFooter />
    </>
  );
}

TrainerProfile.defaultProps = {
  loading: false,
};
