import { makeStyles, Theme } from "@material-ui/core/styles";
import React from "react";
import { useParams } from "react-router-dom";
import logo from "../assets/images/logo-rambody-text.png";
import AppSettings from "../constants/AppSettings";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flip: false,
    textAlign: "center",
    margin: theme.spacing(8, 2, 2, 2),
    direction: "ltr",
    wordBreak: "break-word",
  },
  logo: {
    width: theme.spacing(20),
    height: theme.spacing(5),
  },
}));

export default function ErrorPage(): JSX.Element {
  const classes = useStyles();
  let { errorCode, message, operationId } = useParams<{
    errorCode: string;
    message: string;
    operationId: string;
  }>();
  errorCode = decodeURI(errorCode);
  message = decodeURI(message);
  operationId = decodeURI(operationId);

  return (
    <div className={classes.root}>
      <img className={classes.logo} src={logo} alt="Logo" />
      <h1>Looks like something went wrong.</h1>
      <h2>
        Contact{" "}
        <a href={`mailto:${AppSettings.Links.SupportEmail}`}>Rambody support</a>{" "}
        team
      </h2>
      <h2>Error Details:</h2>
      <h3>Error Code: {errorCode}</h3>
      <h3>Error Message: {message}</h3>
      <h3>Operation Id: {operationId}</h3>
    </div>
  );
}
