import { TutorialVideo } from "../../models/TutorialVideo";
import AppSettings from "../../constants/AppSettings";

const tutorialvideos: TutorialVideo[] = [
  {
    code: 1,
    title: "۱. طریقه درخواست برنامه",
    description:
      "در این قسمت به شما نشان میدهم که چطور از طریق اپلیکیشن رمبادی به من درخواست برنامه دهید.\n۱. بعد از نصب اپلیکیشن وارد صفحه جستجوی مربی شده و آیدی من ({0}) را وارد نمایید.\n۲. وارد پروفایلم شده و یکی از برنامه ها را انتخاب نمایید. توجه داشته باشید که هیچکدام از این برنامه ها از قبل آماده نشده و بر اساس شرایط و هدف شما طراحی می گردد.\n۳. از میان برنامه ها، برنامه ای که بیشتر به هدفتان نزدیک است را انتخاب کنید.\n۴. در پایین صفحه با کلیک بر روی دکمه 'درخواست برنامه' اطلاعات حساب من نشان داده می شود.\n۵. بعد از واریز مبلغ، مجدد وارد اپ شده و دکمه پرداخت کردم را کلیک کنید.\n۶. در صفحه بعد اطلاعات پرداخت را وارد نمایید و برنامه را درخواست دهید.\nدر نهایت در صورت تایید درخواست توسط من (بررسی درخواست ممکن است چند ساعت طول بکشد)، پیغامی مبنی بر قبول درخواست برای شما ارسال میشود. پس از آن باید منتظر بمانید تا در مدت زمان مشخص شده، برنامه را طراحی و ارسال نمایم.",
    fileName: "request_a_plan.mp4",
    thumbFileName: "request_a_plan.jpg",
    filePath: AppSettings.Paths.TutorialVideosCommonFilesPath,
    icon: "request_a_plan.png",
  },
  {
    code: 2,
    title: "۲. پروفایل ورزشکار در اپلیکیشن مربی",
    description:
      "در این فیلم شما می توانید ببینید که چه اطلاعاتی از شما در اپلیکیشن مربی به من نمایش داده می شود.\nمن بر اساس این اطلاعات میتوانم برنامه ای مناسب با شرایط و هدف شما طراحی نمایم.\nهمانطور که در فیلم مشاهده میکنید بر اساس اطلاعات وارد شده، دسترسی کاملی به آنالیز بدنی شما نیز خواهم داشت.\nضمنا بارگزاری عکس تان از سه جهت مختلف، به من کمک میکند تا از فرم بدن تان آگاهی پیدا کنم.\nتوجه: در بارگزاری عکسها قسمت صورت را حذف نمایید.",
    fileName: "client_profile.mp4",
    filePath: AppSettings.Paths.TutorialVideosCommonFilesPath,
    thumbFileName: "client_profile.jpg",
    icon: "client_profile.png",
  },
  {
    code: 3,
    title: "۳. طراحی برنامه تمرینی در اپلیکیشن مربی",
    description:
      "در این فیلم نشان می دهم که چطور یک برنامه تمرینی اختصاصی برای شما طراحی و ارسال می کنم.\nهمانطور که مشاهده میکنید می بایست از میان صدها حرکت تمرینی، حرکتهایی که مناسب شرایط فیزیکی و هدف شما باشند را انتخاب نموده و تعداد جلسات، تعداد ست ها، زمان اجرا و سیستم های تمرینی را تنظیم نمایم.\nتوجه داشته باشید که برای طراحی یک برنامه اختصاصی، زمان زیادی صرف می شود بنابراین امکان ارسال برنامه بصورت فوری وجود ندارد و می بایست چند روز قبل از اتمام دوره فعلی، برنامه دوره بعدی خود را درخواست دهید.",
    fileName: "create_workout.mp4",
    filePath: AppSettings.Paths.TutorialVideosCommonFilesPath,
    thumbFileName: "create_workout.jpg",
    icon: "create_workout.png",
  },
];

export default tutorialvideos;
