import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { Theme, useTheme, withStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import LanguageIcon from "@material-ui/icons/Language";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Language } from "../../models/Language";
import { RootDispatcher } from "../../store/root-reducer";
import { AppState } from "../../store/state";
import { getTheme } from "../../theme";

const InnerButton = withStyles((theme: Theme) => ({
  root: {
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    [theme.breakpoints.down("sm")]: {
      color: theme.palette.background.paper,
      borderColor: theme.palette.background.paper,
    },
  },
}))(Button);

export default function ChangeLanguageButton(): JSX.Element {
  const { i18n } = useTranslation();
  const { userLanguage, languages } = useSelector((state: AppState) => {
    return {
      userLanguage: state.userLanguage,
      languages: state.languages,
    };
  });
  const dispatch = useDispatch();
  const rootDispatcher = new RootDispatcher(dispatch);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  function handleLanguageClick(selectedLanguage: Language) {
    return (): void => {
      setAnchorEl(null);
      if (userLanguage.code !== selectedLanguage.code) {
        rootDispatcher.setLanguageAndTheme(
          selectedLanguage,
          getTheme(selectedLanguage.code)
        );
        i18n.changeLanguage(selectedLanguage.code);
        localStorage.setItem("i18nextLng", selectedLanguage.code);
      }
    };
  }

  function handleMenuClick(
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ): void {
    setAnchorEl(event.currentTarget);
  }

  function handleMenuClose(): void {
    setAnchorEl(null);
  }

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <InnerButton
        startIcon={<LanguageIcon />}
        variant="outlined"
        aria-haspopup="true"
        onClick={handleMenuClick}
      >
        {matches ? userLanguage.abbreviation : userLanguage?.title}
      </InnerButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        {languages.map((x) => (
          <MenuItem
            key={x.code}
            selected={x.code === userLanguage.code}
            onClick={handleLanguageClick(x)}
          >
            {x.title}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
