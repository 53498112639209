import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import logo from "../../assets/images/logo-rambody-text.png";
import smallLogo from "../../assets/images/logo-rambody-white.png";
import NavMenu from "./NavMenu";
import MenuIcon from "./MenuIcon";
import ChangeLanguageButton from "./ChangeLanguageButton";
import AppSettings from "../../constants/AppSettings";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(5, 6, 0, 6),
    height: theme.spacing(11),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1.25, 3, 0, 3),
    },
  },
  logo: {
    width: theme.spacing(20),
    height: theme.spacing(5),
    [theme.breakpoints.down("sm")]: {
      width: theme.spacing(5),
      height: theme.spacing(5),
    },
  },
  logoGrid: {
    zIndex: 1,
  },
}));

export default function AppBar(): JSX.Element {
  const classes = useStyles();
  return (
    <header className={classes.root}>
      <Grid container justify="space-between" alignItems="center">
        <Grid item md={4} className={classes.logoGrid}>
          <Hidden smDown>
            <img className={classes.logo} src={logo} alt="Logo" />
          </Hidden>
          <Hidden mdUp>
            <img className={classes.logo} src={smallLogo} alt="Logo" />
          </Hidden>
        </Grid>
        <Hidden smDown>
          <Grid item md={8}>
            <Grid container spacing={1} justify="flex-end" alignItems="center">
              {AppSettings.ShowNavMenu && (
                <Grid item>
                  <NavMenu />
                </Grid>
              )}
              <Grid item>
                <ChangeLanguageButton />
              </Grid>
            </Grid>
          </Grid>
        </Hidden>
        <Hidden mdUp>
          <Grid item md={8}>
            <Grid container justify="flex-end" alignItems="center">
              <Grid item>
                <ChangeLanguageButton />
              </Grid>
              {AppSettings.ShowNavMenu && (
                <Grid item>
                  <MenuIcon />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Hidden>
      </Grid>
    </header>
  );
}
