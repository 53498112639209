import { TutorialVideo } from "../../models/TutorialVideo";
import AppSettings from "../../constants/AppSettings";

const tutorialvideos: TutorialVideo[] = [
  {
    code: 1,
    title: "۱. كيفية طلب الحزمة",
    description:
      "في هذا القسم، سأوضح لك كيفية طلب حزمة عبر تطبيق رمبادي.\n1. بعد تثبيت التطبيق، ابحث عن هويتي ({0}) في صفحة البحث\n2. أدخل ملف التعريف الخاص بي. اقرأ وصف الحزمة واختر ما يناسب احتياجاتك وهدفك.\nالخطط ليست مصممة مسبقًا. سأصمم خطة مخصصة بناءً على حالتك البدنية وهدفك.\n3. دفع رسوم الحزمة التي اخترتها باستخدام طريقة الدفع الخاصة بي. يمكنك رؤية طريقة الدفع الخاصة بي عن طريق النقر فوق الزر ‘طلب الحزمة’.\n4. بمجرد دفع ثمن الحزمة، انتقل إلى التطبيق وانقر على زر لقد دفعت لإكمال الطلب.\n5. في الصفحة التالية، أدخل معلومات الدفع أو قم بتحميل الإيصال ثم اطلب الطرد.\n6. سوف تتلقى إخطارًا أوليًا عندما وافقت وبدأت في إعداد خطتك. أحتاج إلى بعض الوقت لإعداد خطة مخصصة لك. بمجرد إكمال الخطة، ستتلقى إشعارًا آخر بأن خطتك جاهزة للاستخدام.",
    fileName: "request_a_plan.mp4",
    filePath: AppSettings.Paths.TutorialVideosCommonFilesPath,
    thumbFileName: "request_a_plan.jpg",
    icon: "request_a_plan.png",
  },
  {
    code: 2,
    title: "۲. ملفك الشخصي في تطبيق المدرب",
    description:
      "في هذا الفيديو، يمكنك أن ترى ما هي المعلومات التي تظهر لي في طلب المدرب.\nبناءً على هذه المعلومات، يمكنني تصميم خطة مناسبة لحالتك البدنية وهدفك الرئيسي.\nكما ترى في الفيديو، سيكون لدي وصول كامل إلى تحليلك المادي وجميع صور الجسد التي ربما تكون قد قمت بتحميلها. يُقترح تحميل صور الجسم من ثلاث زوايا مختلفة والتي ستساعدني في معرفة شكل جسمك وتصميم خطة أفضل لك.\nملاحظة: يمكنك اختيار عدم تحميل الصور دون إظهار وجهك.",
    fileName: "client_profile.mp4",
    filePath: AppSettings.Paths.TutorialVideosCommonFilesPath,
    thumbFileName: "client_profile.jpg",
    icon: "client_profile.png",
  },
  {
    code: 3,
    title: "۳. تصميم خطة تدريب في تطبيق المدرب",
    description:
      "كما هو موضح في القسم السابق، سأراجع ملفك الشخصي والهدف الرئيسي وأبدأ التخطيط لك.\nسأختار التدريبات المناسبة لك من بين مئات التدريبات التدريبية الموجودة في مجموعة تمارين رمبادي.\nبالنسبة لكل تمرين، سأقدم المزيد من التفاصيل مثل الجلسات وعدد المجموعات ووقت التنفيذ وأنظمة التدريب.\nهذه العملية سوف تستغرق وقتا. هذا هو السبب في أنني لا أستطيع إرسال خطة لك على الفور. تأكد من طلب خطتك التالية قبل أيام قليلة من نهاية الخطة الحالية.",
    fileName: "create_workout.mp4",
    filePath: AppSettings.Paths.TutorialVideosCommonFilesPath,
    thumbFileName: "create_workout.jpg",
    icon: "create_workout.png",
  },
];

export default tutorialvideos;
