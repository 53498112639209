import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import textureImage from "../../../assets/images/texture.png";
import defaultProfilePhoto from "../../../assets/images/default-profile-photo.png";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: theme.spacing(68),
    height: theme.spacing(68),
    position: "relative",
  },
  textureTop: {
    flip: false,
    position: "absolute",
    width: theme.spacing(49),
    height: theme.spacing(28),
    top: 0,
    left: 0,
    zIndex: 1,
    transform: "rotate(180deg)",
    opacity: 0.7,
  },
  textureBottom: {
    flip: false,
    position: "absolute",
    width: theme.spacing(49),
    height: theme.spacing(28),
    bottom: 0,
    right: 0,
    zIndex: 1,
    opacity: 0.7,
  },
  profile: {
    position: "absolute",
    width: theme.spacing(50),
    height: theme.spacing(50),
    top: theme.spacing(9),
    left: theme.spacing(9),
    zIndex: 2,
    borderRadius: theme.shape.borderRadius * 3.75,
  },
}));

interface Props {
  photoUrl: string | null;
}

export default function ProfileImage({ photoUrl }: Props): JSX.Element {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <img src={textureImage} alt="" className={classes.textureTop} />
      <img
        src={photoUrl || defaultProfilePhoto}
        alt=""
        className={classes.profile}
      />
      <img src={textureImage} alt="" className={classes.textureBottom} />
    </div>
  );
}
