import { TutorialVideo } from "../../models/TutorialVideo";
import AppSettings from "../../constants/AppSettings";

const tutorialvideos: TutorialVideo[] = [
  {
    code: 1,
    title: "1. How to request a package",
    description:
      "In this section, I will show you how to request a package via the Rambody application.\n1. After installing the application, search for my ID ({0}) in the search page\n2. Enter my profile. Read the description of the package and choose the one that fits your needs and goal.\nPlans are not pre-designed. I will design a custom plan based on your physical condition and goal.\n3. Pay for your selected package fee using your my payment method. You can see my payment method by clicking the ‘Request Package’ button.\n4. Once you have paid for the package go to the app and click on the 'I paid' button to complete the request.\n5. On the next page, enter the payment information or upload your receipt then request the package.\n6. You will receive an initial notification when I approved and started to prepare your plan. I needs some time to prepare a custom plan for you. Once I completed the plan you will receive another notification that your plan is ready to use.",
    fileName: "request_a_plan.mp4",
    filePath: AppSettings.Paths.TutorialVideosCommonFilesPath,
    thumbFileName: "request_a_plan.jpg",
    icon: "request_a_plan.png",
  },
  {
    code: 2,
    title: "2. Your profile in the Trainer application",
    description:
      "In this video, you can see what information is displayed to me in trainer's application.\nBased on this information, I can design a plan suitable for your physical condition and main goal.\nAs you can see in the video, I will have full access to your physical analysis and all the body images you might have uploaded. It is suggested to upload body images from three different angles which will help me to know your body shape and better design a plan for you.\nNote: You can choose not to upload photos without showing your face.",
    fileName: "client_profile.mp4",
    filePath: AppSettings.Paths.TutorialVideosCommonFilesPath,
    thumbFileName: "client_profile.jpg",
    icon: "client_profile.png",
  },
  {
    code: 3,
    title: "3. How your trainer designs a custom workout",
    description:
      "As described in the previous section, I will review your profile and main goal and starts the planning for you.\nI will choose the right exercises for you from the hundreds of training exercises that exist in the Rambody exercises collection.\nFor each exercise, I will provide more details such as sessions, the number of sets, the execution time, and the training systems.\nThis process will take time. That is the reason I can not immediately send a plan to you. Make sure to request your next plan a few days before the end of the current plan.",
    fileName: "create_workout.mp4",
    filePath: AppSettings.Paths.TutorialVideosCommonFilesPath,
    thumbFileName: "create_workout.jpg",
    icon: "create_workout.png",
  },
];

export default tutorialvideos;
