import { Screenshot } from "../../models/Screenshot";
import AppSettings from "../../constants/AppSettings";

const screenshots: Screenshot[] = [
  {
    code: 1,
    title: "انیمیشن اجرای حرکات",
    description:
      "در رمبادی بیش از ۱۵۰۰ حرکت تمرینی وجود دارد که من به عنوان مربی با انتخاب از میان این حرکات یک برنامه تمرینی اختصاصی برای شما طراحی و ارسال می کنم. توجه داشته باشید که هیچ کدام از این برنامه ها از قبل آماده نشده و من بر اساس نیاز شما و پس از مدت زمان مشخص شده،‌ برنامه را طراحی و ارسال میکنم.",
    fileName: "screenshot1.gif",
    filePath: AppSettings.Paths.ScreenshotsCommonFilesPath,
  },
  {
    code: 2,
    title: "عضلات درگیر",
    description:
      "در برنامه ارسال شده مشخص میکنم که کدام عضلات شما در حین انجام هر حرکت درگیر می شود. با این امکان شما در حین انجام حرکت میتوانید بر روی عضله هدف تمرکز کنید.",
    fileName: "screenshot2.jpg",
    filePath: AppSettings.Paths.ScreenshotsCommonFilesPath,
  },
  {
    code: 3,
    title: "برنامه بدون تجهیزات",
    description:
      "در صورتی که برای من مشخص کنید که تجهیزات باشگاهی در دسترس ندارید، با توجه به تنوع حرکات، برنامه ای اختصاصی بدون نیاز به تجهیزات ورزشی و با کمک وزن بدن تان طراحی می کنم.",
    fileName: "screenshot3.gif",
    filePath: AppSettings.Paths.ScreenshotsCommonFilesPath,
  },
  {
    code: 4,
    title: "نمودار پیشرفت",
    description:
      "با وارد کردن وزن و سایزتان می توانید تغییرات بوجود آمده را بر روی نمودار مشاهده کنید و من با دیدن نمودار و سرعت تغییر و آنالیز شرایط بدنی تان، می توانم برنامه را تغییر داده تا هر چه سریعتر و بهتر به هدف برسید.",
    fileName: "screenshot4.jpg",
    filePath: AppSettings.Paths.ScreenshotsCommonFilesPath,
  },
];

export default screenshots;
