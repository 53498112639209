import en from "./en";
import fa from "./fa";
import ar from "./ar";
import { LanguageCodes } from "../../constants/enums";
import { TutorialVideo } from "../../models/TutorialVideo";

const tutorialVideos: { [languageCode: string]: TutorialVideo[] } = {
  [LanguageCodes.English]: en,
  [LanguageCodes.Persian]: fa,
  [LanguageCodes.Arabic]: ar,
};

export default tutorialVideos;
