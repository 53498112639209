import Hidden from "@material-ui/core/Hidden";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { formatString } from "@rambody/commons/lib/extentions/string-helper";
import React, { useRef } from "react";
import { isAndroid } from "react-device-detect";
import { useTranslation } from "react-i18next";
import AppSettings from "../../../constants/AppSettings";
import { useClampedIsInViewport } from "../../../plugins/use-clamped-is-in-viewport";
import BootstrapButton from "../../Common/BootstrapButton";

const useStyles = makeStyles((theme: Theme) => ({
  profileName: {
    color: theme.palette.text.primary,
    fontFamily: theme.profile.name.fontFamily,
    fontSize: theme.typography.pxToRem(40),
    fontWeight: "bolder",
    margin: 0,
    [theme.breakpoints.down("sm")]: {
      color: theme.palette.common.white,
      fontSize: theme.typography.pxToRem(28),
    },
    textTransform: "uppercase",
  },
  profileId: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.pxToRem(20),
    fontWeight: "normal",
    margin: theme.spacing(1, 0, 0, 0),
    [theme.breakpoints.down("sm")]: {
      color: "rgba(255,255,255)",
      fontSize: theme.typography.pxToRem(17.6),
    },
  },
  openApp: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.typography.pxToRem(14),
    },
  },
}));

interface Props {
  fullName: string | null;
  trainerId: string | null;
}

export default function ProfileName({
  fullName,
  trainerId,
}: Props): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();

  const ref = useRef(null);
  const [isInViewport, targetRef] = useClampedIsInViewport({
    target: ref,
  });

  const handleOpenAppClick = (): void => {
    // Open the app if is installed otherwise do nothing.
    window.location.href = formatString(
      AppSettings.Links.OpenAppLink,
      trainerId || ""
    );
    setTimeout((): void => {
      if (isAndroid) {
        window.location.href = AppSettings.Links.GooglePlayStoreLink;
      } else {
        window.location.href = AppSettings.Links.AppStoreLink;
      }
    }, 1000);
  };

  return (
    <>
      <div ref={targetRef}>
        <h1
          className={`${classes.profileName} ${
            isInViewport ? "animate__animated animate__fadeInDown" : "hide"
          }`}
        >
          {fullName}
        </h1>
        <h6
          style={{ animationDelay: "400ms" }}
          className={`${classes.profileId} ${
            isInViewport ? "animate__animated animate__fadeInDown" : "hide"
          }`}
        >
          <span dir="ltr">{`@${trainerId}`}</span>
        </h6>
      </div>
      <Hidden mdUp>
        <BootstrapButton
          className={classes.openApp}
          onClick={handleOpenAppClick}
        >
          {t("OpenApp")}
        </BootstrapButton>
      </Hidden>
    </>
  );
}
