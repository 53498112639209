import Collapse from "@kunukn/react-collapse";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { ISingleLanguageSport } from "@rambody/db-schema/lib/user/IUser";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import AppSettings from "../../constants/AppSettings";
import { useClampedIsInViewport } from "../../plugins/use-clamped-is-in-viewport";
import ReadMore from "../Common/ReadMore";

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    fontWeight: theme.title.fontWeight,
    color: theme.palette.text.primary,
    fontSize: theme.title.fontSize,
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.title.downSm.fontSize,
    },
  },
  content: {
    fontWeight: "bold",
  },
  icon: {
    fontSize: theme.typography.pxToRem(11),
    color: theme.palette.primary.main,
  },
  iconItem: {
    minWidth: theme.spacing(2),
  },
  expert: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: "lighter",
  },
  collapse: {
    transition: theme.transitions.create("height", {
      duration: theme.transitions.duration.standard,
      easing: theme.transitions.easing.easeInOut,
    }),
  },
  listPadding: {
    paddingTop: theme.spacing(1),
    paddingBottom: 0,
  },
  listItem: {
    [theme.breakpoints.down("xs")]: {
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5),
    },
  },
  gutters: {
    paddingLeft: 0,
  },
}));

interface Props {
  expertise: (ISingleLanguageSport | null)[] | null;
}

interface ExpertiseItemsProps {
  expertise: (ISingleLanguageSport | null)[];
  isInViewport: boolean | null;
}

function ExpertiseItems({
  expertise,
  isInViewport,
}: ExpertiseItemsProps): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <>
      {expertise.map((sport, index) => {
        return (
          sport &&
          sport.sport_id && (
            <ListItem
              classes={{ root: classes.listItem, gutters: classes.gutters }}
              key={sport.sport_id}
              button
              style={{
                animationDelay: `${index * AppSettings.SubItemsShowDelay}ms`,
              }}
              className={`${
                isInViewport ? "animate__animated animate__fadeInLeft" : "hide"
              }`}
            >
              <ListItemIcon className={classes.iconItem}>
                <FiberManualRecordIcon className={classes.icon} />
              </ListItemIcon>
              <ListItemText>
                <span className={classes.content}>{sport.name || ""}</span>
                &nbsp;
                <span className={classes.expert}>
                  ({t((sport.knowledge || "").toLowerCase())})
                </span>
              </ListItemText>
            </ListItem>
          )
        );
      })}
    </>
  );
}

export default function Expertise({ expertise }: Props): JSX.Element {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const { t } = useTranslation();

  const ref = useRef(null);
  const [isInViewport, targetRef] = useClampedIsInViewport({
    target: ref,
  });

  function handleReadMoreStateChanged(state: boolean): void {
    setExpanded(state);
  }

  const theme = useTheme();
  const isDownxs = useMediaQuery(theme.breakpoints.down("xs"));
  // Allow collapse expertise list when it's length is greater than 4
  const allowCollapse = expertise && expertise.length > (isDownxs ? 5 : 4);

  return (
    <div ref={targetRef}>
      <Typography variant="h4" className={classes.title}>
        {t("ExpertiseTitle")}
      </Typography>
      <List classes={{ padding: classes.listPadding }}>
        {allowCollapse && (
          <Collapse
            isOpen={expanded}
            collapseHeight="200px"
            className={classes.collapse}
          >
            {expertise && (
              <ExpertiseItems
                expertise={expertise}
                isInViewport={isInViewport}
              />
            )}
          </Collapse>
        )}
        {!allowCollapse && expertise && (
          <ExpertiseItems expertise={expertise} isInViewport={isInViewport} />
        )}
      </List>
      {allowCollapse && (
        <ReadMore onStateChanged={handleReadMoreStateChanged} />
      )}
    </div>
  );
}
