import { Screenshot } from "../../models/Screenshot";
import AppSettings from "../../constants/AppSettings";

const screenshots: Screenshot[] = [
  {
    code: 1,
    title: "Animated exercises",
    description:
      "There are more than 1,500 training exercises exist in Rambody, and as a trainer, I will design and send you a special workout plan by choosing from these exercises. Note that these plans haven't been prepared in advance and I will design and submit the plan based on your needs and after the specified time.",
    fileName: "screenshot1.gif",
    filePath: AppSettings.Paths.ScreenshotsCommonFilesPath,
  },
  {
    code: 2,
    title: "Muscles involved",
    description:
      "In the sent workout plan, I specify which of your muscles are involved during each exercise. With this feature, you can focus on the target muscle while doing.",
    fileName: "screenshot2.jpg",
    filePath: AppSettings.Paths.ScreenshotsCommonFilesPath,
  },
  {
    code: 3,
    title: "Workout plan without equipment",
    description:
      "If you indicate to me that you do not have equipment available, according to the variety of exercises, I will design a special plan without the need for any equipment and with the help of your body weight.",
    fileName: "screenshot3.gif",
    filePath: AppSettings.Paths.ScreenshotsCommonFilesPath,
  },
  {
    code: 4,
    title: "Progress chart",
    description:
      "By entering your weight and size, see the changes on the chart, and base on the chart and the speed of your changes and by analyze your physical condition, I can change your plan to reach your goal faster and better.",
    fileName: "screenshot4.jpg",
    filePath: AppSettings.Paths.ScreenshotsCommonFilesPath,
  },
];

export default screenshots;
