import React from "react";
import LinearProgress from "@material-ui/core/LinearProgress";
import { makeStyles, Theme } from "@material-ui/core/styles";
import logo from "../../assets/images/logo-rambody.png";

const useStyles = makeStyles((theme: Theme) => ({
  loader: {
    width: theme.spacing(19),
    height: theme.spacing(19),
    lineHeight: theme.spacing(19),
    margin: "100px auto",
    position: "relative",
    boxSizing: "border-box",
    textAlign: "center",
    zIndex: 0,
    textTransform: "uppercase",
    "& img": {
      width: theme.spacing(8),
      height: theme.spacing(8),
      position: "absolute",
      top: "45px",
      left: "45px",
    },
    "&:before": {
      opacity: "0",
      boxSizing: "border-box",
      content: '"\\0020"',
      position: "absolute",
      top: "0",
      left: "0",
      width: "100%",
      height: "100%",
      borderRadius: "100px",
      boxShadow: theme.shadow.appIconStrong,
      zIndex: "2",
      animation: "$gogoloader 2s infinite",
    },
    "&:after": {
      opacity: "0",
      boxSizing: "border-box",
      content: '"\\0020"',
      position: "absolute",
      top: "0",
      left: "0",
      width: "100%",
      height: "100%",
      borderRadius: "100px",
      boxShadow: theme.shadow.appIconStrong,
      zIndex: "1",
      animation: "$gogoloader 2s infinite 1s",
    },
  },
  "@keyframes gogoloader": {
    "0%": {
      transform: "scale(0)",
      opacity: 0,
    },
    "50%": {
      opacity: 1,
    },
    "100%": {
      transform: "scale(1)",
      opacity: 0,
    },
  },
}));

export default function PageLoading(): JSX.Element {
  const classes = useStyles();
  return (
    <>
      <LinearProgress />
      <div className={classes.loader}>
        <img src={logo} alt="" />
      </div>
    </>
  );
}
