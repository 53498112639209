import Button from "@material-ui/core/Button";
import ButtonBase from "@material-ui/core/ButtonBase";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { formatString } from "@rambody/commons/lib/extentions/string-helper";
import { IShortFinancialPlan } from "@rambody/db-schema/lib/user/IUser";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import appStore from "../../assets/images/app-store-gray.png";
import defaultPlanImage from "../../assets/images/default-plan.jpg";
import directAndroid from "../../assets/images/direct_android.png";
import googlePlay from "../../assets/images/google-play-gray.png";
import AppSettings from "../../constants/AppSettings";
import { LanguageCodes } from "../../constants/enums";
import { AppState } from "../../store/state";
import SlideTransition from "../Transition/Slide";

const useStyles = makeStyles((theme: Theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(3),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
    zIndex: 11,
  },
  helpTitle: {
    fontSize: theme.typography.pxToRem(24),
    textTransform: "uppercase",
    fontFamily: theme.myPlans.font,
    paddingLeft: theme.spacing(2),
    margin: theme.spacing(1, 0),
  },
  hr: {
    opacity: 0.3,
    margin: 0,
  },
  planName: {
    position: "relative",
    zIndex: 10,
    fontSize: theme.typography.pxToRem(24),
    color: theme.palette.common.white,
  },
  planDescription: {
    position: "relative",
    zIndex: 10,
    color: theme.palette.common.white,
  },
  listItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "baseline",
    padding: theme.spacing(0.5, 2),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(2, 2),
    },
  },
  listTitle: {
    paddingRight: theme.spacing(1.5),
    fontFamily: theme.myPlans.font,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 400,
    textTransform: "uppercase",
    flexShrink: 0,
    flexGrow: 0,
  },
  listDescription: {
    opacity: 0.7,
    flexGrow: 1,
    fontSize: theme.typography.pxToRem(16),
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.typography.pxToRem(15),
    },
  },
  downloadButton: {
    width: theme.spacing(17.5),
    height: theme.spacing(5),
    borderRadius: theme.shape.borderRadius * 2,
  },
  downloadButtonContainer: {
    paddingTop: theme.spacing(3),
  },
  info: {
    borderRadius: theme.shape.borderRadius * 2,
    textAlign: "center",
    padding: theme.spacing(1),
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    position: "relative",
    "&:before": {
      zIndex: 0,
      pointerEvents: "none",
      content: "''",
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      display: "block",
      backgroundColor: theme.palette.common.black,
      opacity: 0.5,
      borderRadius: theme.shape.borderRadius,
    },
  },
  dialogPaper: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      minWidth: "95vw",
      maxWidth: "95vw",
    },
  },
}));

interface Props {
  open: boolean;
  onClose: () => void;
  trainerId: string;
  financialPlan: IShortFinancialPlan | null;
}

export default function MyPlansGuideDialog(props: Props): JSX.Element {
  const classes = useStyles();
  const { theme } = useSelector((state: AppState) => {
    return {
      theme: state.theme,
    };
  });
  const { open, onClose, trainerId, financialPlan } = props;
  const { t } = useTranslation();

  const { userLanguage } = useSelector((state: AppState) => {
    return {
      userLanguage: state.userLanguage,
    };
  });

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="md"
      TransitionComponent={SlideTransition}
      keepMounted
      classes={{ paper: classes.dialogPaper }}
    >
      <DialogContent>
        <Grid container direction="row" dir={theme.direction} spacing={2}>
          <Grid
            item
            xs={12}
            md={6}
            className={classes.info}
            style={{
              backgroundImage: `url('${
                financialPlan?.image_url || defaultPlanImage
              }')`,
            }}
          >
            <p className={classes.planName} dir={theme.direction}>
              {financialPlan?.plan_name}
            </p>
            <p className={classes.planDescription}>
              {financialPlan?.description}
            </p>
          </Grid>
          <Grid item xs={12} md={6}>
            <p className={classes.helpTitle} dir={theme.direction}>
              {t("HowToApplyProgram")}
            </p>
            <hr className={classes.hr} />
            <div className={classes.listItem}>
              <div className={classes.listTitle}>{t("Level")} 1</div>
              <div className={classes.listDescription}>
                {t("ApplyProgramLevel1")}
              </div>
            </div>
            <div className={classes.listItem}>
              <div className={classes.listTitle}>{t("Level")} 2</div>
              <div className={classes.listDescription}>
                {formatString(t("ApplyProgramLevel2"), trainerId)}
              </div>
            </div>
            <div className={classes.listItem}>
              <div className={classes.listTitle}>{t("Level")} 3</div>
              <div className={classes.listDescription}>
                {t("ApplyProgramLevel3")}
              </div>
            </div>
            <div className={classes.listItem}>
              <div className={classes.listTitle}>{t("Level")} 4</div>
              <div className={classes.listDescription}>
                {t("ApplyProgramLevel4")}
              </div>
            </div>
            <Grid item xs={12}>
              <Grid
                container
                spacing={1}
                justify="center"
                wrap="wrap"
                className={classes.downloadButtonContainer}
              >
                <Grid item>
                  <ButtonBase href={AppSettings.Links.GooglePlayStoreLink}>
                    <img
                      src={googlePlay}
                      alt="Google Play"
                      className={classes.downloadButton}
                    />
                  </ButtonBase>
                </Grid>
                <Grid item>
                  <ButtonBase href={AppSettings.Links.AppStoreLink}>
                    <img
                      src={appStore}
                      alt="App Store"
                      className={classes.downloadButton}
                    />
                  </ButtonBase>
                </Grid>
                {userLanguage.code === LanguageCodes.Persian && (
                  <Grid item>
                    <ButtonBase
                      href={AppSettings.Links.AndroidDirectDownloadLink}
                    >
                      <img
                        src={directAndroid}
                        alt="Android APK"
                        className={classes.downloadButton}
                      />
                    </ButtonBase>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" autoFocus onClick={onClose} color="primary">
          {t("Close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
