import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { ISingleLanguageSport } from "@rambody/db-schema/lib/user/IUser";
import React from "react";
import AboutMe from "./AboutMe";
import Expertise from "./Expertise";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    paddingTop: theme.spacing(5),
  },
  aboutMe: {
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(5),
    },
  },
}));

interface Props {
  overview: string | null;
  expertise: (ISingleLanguageSport | null)[] | null;
}

export default function AboutMeAndExpertise({
  expertise,
  overview,
}: Props): JSX.Element {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Container maxWidth="md">
        <Grid container>
          <Grid item xs={12} lg={6}>
            <Expertise expertise={expertise} />
          </Grid>
          <Grid item xs={12} lg={6} className={classes.aboutMe}>
            <AboutMe overview={overview} />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
