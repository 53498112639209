import Button from "@material-ui/core/Button";
import { withStyles, Theme } from "@material-ui/core/styles";

const BootstrapButton = withStyles((theme: Theme) => ({
  root: {
    boxShadow: "none",
    textTransform: "none",
    fontSize: theme.typography.pxToRem(16),
    padding: theme.spacing(1, 2),
    backgroundColor: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    width: theme.spacing(16),
    height: theme.spacing(5),
    borderRadius: theme.shape.borderRadius * 8,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
}))(Button);

export default BootstrapButton;
