import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { combinePath } from "@rambody/commons/lib/extentions/path-helper";
import { formatString } from "@rambody/commons/lib/extentions/string-helper";
import React, { useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import { useSelector } from "react-redux";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { Swiper, SwiperSlide } from "swiper/react";
import useIsInViewport from "use-is-in-viewport";
import screenshots from "../../assets/screenshots";
import AppSettings from "../../constants/AppSettings";
import { AppState } from "../../store/state";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    overflow: "hidden",
  },
  container: {
    position: "relative",
    paddingBottom: theme.spacing(4),
    paddingTop: theme.spacing(4),
    [theme.breakpoints.up("sm")]: {
      marginRight: theme.spacing(0),
      paddingRight: theme.spacing(0),
      maxWidth: `calc(100vw - ((100vw - ${theme.breakpoints.values.md}px) / 2))`,
    },
  },
  slider: {
    position: "relative",
    padding: theme.spacing(2, 0),
    [theme.breakpoints.down("sm")]: {
      marginLeft: theme.spacing(-3),
      minWidth: "100vw",
    },
    [theme.breakpoints.up("md")]: {
      "&:after": {
        zIndex: 10,
        pointerEvents: "none",
        content: "''",
        position: "absolute",
        top: 0,
        left: -1,
        width: "100%",
        height: "100%",
        display: "block",
        background: theme.gradient.boxLeftHide98,
      },
    },
  },
  slide: {
    width: theme.spacing(47),
    height: theme.spacing(36),
    [theme.breakpoints.down("sm")]: {
      width: theme.spacing(41),
      height: theme.spacing(31),
    },
    borderRadius: theme.shape.borderRadius * 2.5,
    margin: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadow.screenshotItem,
    padding: 0,
    "& img": {
      width: "100%",
      height: "100%",
    },
  },
  screenshots: {
    position: "relative",
    marginTop: theme.spacing(5),
    paddingRight: theme.spacing(1),
  },
  pagination: {
    position: "absolute",
    bottom: theme.spacing(8),
    left: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      bottom: theme.spacing(1),
      right: theme.spacing(4),
      textAlign: "center",
    },
  },
  bullet: {
    marginRight: theme.spacing(1),
  },
  bulletActive: {
    opacity: 0.8,
    position: "relative",
    "&:after": {
      zIndex: 10,
      pointerEvents: "none",
      content: "''",
      position: "absolute",
      top: -3,
      left: -3,
      right: -3,
      bottom: -3,
      background: "transparent",
      border: `1px solid ${theme.palette.common.black}`,
      borderRadius: "50%",
    },
  },
  title: {
    fontWeight: theme.title.fontWeight,
    color: theme.palette.text.primary,
    fontSize: theme.title.fontSize,
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.title.downSm.fontSize,
    },
  },
  description: {
    paddingTop: theme.spacing(2.75),
    lineHeight: "22px",
    maxHeight: theme.spacing(26.25),
    overflow: "hidden",
    textAlign: "justify",
  },
  image: {
    borderRadius: theme.shape.borderRadius * 2.5,
  },
  hideText: {
    color: "transparent",
  },
}));

export default function Screenshots(): JSX.Element {
  const classes = useStyles();
  const [swiperInstance, setSwiperInstance] = useState<
    Parameters<Required<Swiper>["onSwiper"]>[0] | null
  >(null);
  const { userLanguage } = useSelector((state: AppState) => {
    return {
      userLanguage: state.userLanguage,
    };
  });

  const [slideIndex, setSlideIndex] = useState(0);

  useEffect(() => {
    return (): void => {
      if (swiperInstance) {
        swiperInstance.destroy();
      }
    };
    // "react-hooks/exhaustive-deps" is disabled because dependency list must be empty to only run the effect on component unmout.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSlideChange = (
    s: Parameters<Required<Swiper>["onSwiper"]>[0]
  ): void => {
    setSlideIndex(s.realIndex);
  };

  const ref = useRef(null);
  const [isInViewport, targetRef] = useIsInViewport({
    target: ref,
  });

  useEffect(() => {
    if (swiperInstance && isInViewport) {
      swiperInstance.autoplay.start();
    } else if (swiperInstance && !isInViewport) {
      swiperInstance.autoplay.stop();
    }
  }, [isInViewport, swiperInstance]);

  return (
    <div className={classes.root} ref={targetRef}>
      <Container maxWidth="md" className={classes.container}>
        <Grid container>
          <Grid item xs={12} md={4} className={classes.screenshots}>
            <SwitchTransition mode="out-in">
              <CSSTransition
                appear
                key={!isInViewport ? -1 : slideIndex}
                addEndListener={(node, done): void => {
                  node.addEventListener("transitionend", done, false);
                }}
                classNames="fade-slide-x-10"
              >
                <div>
                  <Typography
                    variant="h4"
                    className={`${
                      !isInViewport ? classes.hideText : classes.title
                    }`}
                  >
                    {screenshots[userLanguage.code][slideIndex]?.title}
                  </Typography>
                  <div
                    className={`${
                      !isInViewport ? classes.hideText : classes.description
                    }`}
                  >
                    {screenshots[userLanguage.code][slideIndex]?.description}
                  </div>
                </div>
              </CSSTransition>
            </SwitchTransition>
          </Grid>
          <Grid item xs={12} md={8} className={classes.slider}>
            <Swiper
              slidesPerView="auto"
              centeredSlides={isMobile}
              lazy
              loop
              grabCursor
              autoplay={{
                delay: AppSettings.SlidesAutoPlayDelay,
                disableOnInteraction: true,
              }}
              pagination={{
                clickable: true,
                el: `.${classes.pagination}`,
                bulletActiveClass: classes.bulletActive,
                renderBullet: (_, className): string => {
                  return `<span class="${className} ${classes.bullet}"></span>`;
                },
              }}
              onSwiper={setSwiperInstance}
              onSlideChange={handleSlideChange}
            >
              {screenshots[userLanguage.code].map((screenshot) => {
                return (
                  <SwiperSlide key={screenshot.code} className={classes.slide}>
                    <img
                      className={classes.image}
                      src={
                        screenshot.filePath
                          ? combinePath(
                              screenshot.filePath,
                              screenshot.fileName
                            )
                          : combinePath(
                              formatString(
                                AppSettings.Paths.ScreenshotsFilesPath,
                                userLanguage.code
                              ),
                              screenshot.fileName
                            )
                      }
                      alt={screenshot.title}
                    />
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </Grid>
        </Grid>
        <div className={classes.pagination} />
      </Container>
    </div>
  );
}
