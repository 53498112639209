export default {
  translation: {
    OpenApp: "Open app",
    FAQ: "FAQ",
    AboutUs: "About us",
    Tutorials: "Tutorials",
    Blog: "Blog",
    MyPlansTitle: "My packages",
    MyPlansDescription:
      "You can see the list of my packages that I can design in this section. Note that these packages have not been prepared in advance and are designed based on your physical conditions and goal. You can also contact me via the chat button in my profile after installing the Rambody application.",
    ExpertiseTitle: "My Expertise",
    AboutMeTitle: "My overview",
    DownloadApps: "Download Application",
    DownloadDescription: "",
    TutorialVideo: "Tutorial",
    TutorialVideoDescription:
      "Watch tutorial videos here for better use and knowledge of how to design training plans.",
    CompanyDescription:
      "Rambody is a communication platform between trainers and trainees to provide a dedicated training and nutrition plan.",
    AllRightsReserved: "All rights reserved 2019. Farosa Technologies Inc.",
    CompanyName: "Farosa Technologies Inc",
    Level: "Step",
    HowToApplyProgram: "How do I apply for a package?",
    ApplyProgramLevel1: "Download Rambody application",
    ApplyProgramLevel2: "Search for my ID ({0}) in the app",
    ApplyProgramLevel3: "Apply for one based on your needs and goals",
    ApplyProgramLevel4:
      "Design and submit the application within the specified time",
    Close: "Close",
    ReadMore: "More",
    Less: "Less",
    experimental: "Experimental",
    expert: "Expert",
    amateur: "Amateur",
    totalSentPlans: "Total Sent Plans",
  },
};
