import gql from "graphql-tag";

export const GET_TRAINER_INFO = gql`
  query GetTrainerWebsiteInfo($rambodyId: String!, $langCode: LanguageCode) {
    GetTrainerInfo: GetTrainerWebsiteInfo(
      input: { rambodyId: $rambodyId, langCode: $langCode }
    ) {
      _id
      fullname
      firstname
      lastname
      morabix_id
      photo_url
      mobile
      overview
      expertise {
        sport_id
        knowledge
        name
      }
      financial_plans {
        plan_name
        description
        price
        currency
        currency_symbol
        image_url
      }
      telegram_id
      whatsapp_number
      twitter_url
      linkedin_url
      instagram_url
      facebook_url
    }
  }
`;
