import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import logo from "../assets/images/logo-rambody-text.png";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flip: false,
    textAlign: "center",
    margin: theme.spacing(8, 2, 2, 2),
    direction: "ltr",
  },
  logo: {
    width: theme.spacing(20),
    height: theme.spacing(5),
  },
  location: {
    fontSize: theme.typography.pxToRem(21),
    fontWeight: 300,
  },
}));

export default function ErrorPage(): JSX.Element {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <img className={classes.logo} src={logo} alt="Logo" />
      <h2>Please search trainers by adding the trainer ID to URL</h2>
      <h2>
        Example:{" "}
        <span className={classes.location}>{window.location.origin}</span>
        /test
      </h2>
    </div>
  );
}
