import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { numberWithCommas } from "@rambody/commons/lib/extentions/number-helper";
import { IShortFinancialPlan } from "@rambody/db-schema/lib/user/IUser";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import defaultPlanImage from "../../assets/images/default-plan.jpg";
import textureImage from "../../assets/images/texture.png";
import AppSettings from "../../constants/AppSettings";
import { useClampedIsInViewport } from "../../plugins/use-clamped-is-in-viewport";
import MyPlansGuideDialog from "./MyPlansGuideDialog";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingTop: theme.spacing(9),
    paddingBottom: theme.spacing(9),
    backgroundColor: theme.header.background.main,
  },
  slideContainer: {
    "& .swiper-wrapper .swiper-slide-active": {
      boxShadow: theme.shadow.palnItem,
      "&:after": {
        display: "none",
      },
      "&:before": {
        zIndex: 1,
        pointerEvents: "none",
        content: "''",
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        display: "block",
        backgroundColor: theme.palette.common.black,
        opacity: 0.35,
        borderRadius: theme.shape.borderRadius * 2.5,
      },
    },
    "& .swiper-wrapper .swiper-slide": {
      position: "relative",
      "&:after": {
        borderRadius: theme.shape.borderRadius * 2.5,
        zIndex: 9,
        pointerEvents: "none",
        content: "''",
        position: "absolute",
        top: theme.spacing(0),
        left: theme.spacing(0),
        width: "100%",
        height: "100%",
        display: "block",
        backgroundColor: theme.gradient.itemBlure,
      },
    },
    padding: theme.spacing(4),
  },
  slide: {
    width: theme.spacing(39.5),
    height: theme.spacing(40),
    borderRadius: theme.shape.borderRadius * 2.5,
    backgroundRepeat: "no-repeat",
    backgroundSize: "auto",
    position: "relative",
    boxSizing: "border-box",
    cursor: "pointer",
  },
  planInfo: {
    padding: theme.spacing(2),
    zIndex: 10,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    color: theme.palette.common.white,
  },
  planPrice: {
    zIndex: 10,
    boxShadow: "none",
    textTransform: "none",
    fontSize: theme.typography.pxToRem(16),
    padding: theme.spacing(1, 2),
    backgroundColor: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: theme.shape.borderRadius * 8,
    alignSelf: "flex-start",
  },
  planTitleDescription: {
    zIndex: 10,
    height: theme.spacing(31),
    paddingTop: theme.spacing(19),
  },
  planTitle: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    marginBottom: 0,
  },
  planDescription: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  bullet: {
    marginRight: theme.spacing(1),
  },
  bulletActive: {
    opacity: 0.8,
    position: "relative",
    "&:after": {
      zIndex: 10,
      pointerEvents: "none",
      content: "''",
      position: "absolute",
      top: -3,
      left: -3,
      right: -3,
      bottom: -3,
      background: "transparent",
      border: `1px solid ${theme.palette.common.black}`,
      borderRadius: "50%",
    },
  },
  header: {
    marginBottom: theme.spacing(2),
    textAlign: "center",
  },
  title: {
    fontWeight: theme.title.fontWeight,
    color: theme.palette.text.primary,
    fontSize: theme.title.fontSize,
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.title.downSm.fontSize,
    },
  },
  description: {
    maxWidth: theme.spacing(63),
    marginTop: theme.spacing(2.75),
    marginLeft: "auto",
    marginRight: "auto",
    lineHeight: "22px",
  },
  pagination: {
    textAlign: "center",
  },
  sliderWrapper: {
    position: "relative",
    marginTop: theme.spacing(2),
  },
  textureTop: {
    flip: false,
    position: "absolute",
    width: theme.spacing(49),
    height: theme.spacing(29),
    top: theme.spacing(0),
    left: theme.spacing(-9),
    zIndex: 1,
    transform: "rotate(180deg)",
    opacity: 0.7,
  },
}));

interface Props {
  financialPlans: (IShortFinancialPlan | null)[] | null;
  trainerId: string;
}

export default function MyPlans({
  financialPlans,
  trainerId,
}: Props): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();
  const [swiperInstance, setSwiperInstance] = useState<
    Parameters<Required<Swiper>["onSwiper"]>[0] | null
  >(null);
  const [selectedFinancialPlan, setSelectedFinancialPlan] =
    useState<IShortFinancialPlan | null>(null);
  const [dialogIsOpen, setDialogIsOpen] = useState(false);

  const ref = useRef(null);
  const [isInViewport, targetRef] = useClampedIsInViewport({
    target: ref,
  });

  useEffect(() => {
    return (): void => {
      if (swiperInstance) {
        swiperInstance.destroy();
      }
    };
    // "react-hooks/exhaustive-deps" is disabled because dependency list must be empty to only run the effect on component unmout.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (swiperInstance && isInViewport) {
      swiperInstance.autoplay.start();
    } else if (swiperInstance && !isInViewport) {
      swiperInstance.autoplay.stop();
    }
  }, [isInViewport, swiperInstance]);

  const closeDialog = (): void => {
    setDialogIsOpen(false);
  };
  const openDialog = (fp: IShortFinancialPlan) => {
    return async (): Promise<void> => {
      setSelectedFinancialPlan(fp);
      setDialogIsOpen(true);
    };
  };

  return (
    <div className={classes.root} ref={targetRef}>
      <Container maxWidth="md">
        <Grid container>
          <Grid item xs={12} className={classes.header}>
            <Typography
              variant="h4"
              className={`${classes.title} ${
                isInViewport ? "animate__animated animate__fadeInDown" : "hide"
              }`}
            >
              {t("MyPlansTitle")}
            </Typography>
            <p
              style={{ animationDelay: "400ms" }}
              className={`${classes.description} ${
                isInViewport ? "animate__animated animate__fadeInDown" : "hide"
              }`}
            >
              {t("MyPlansDescription")}
            </p>
          </Grid>
          <Grid item xs={12} className={classes.sliderWrapper}>
            <img src={textureImage} alt="" className={classes.textureTop} />
            {financialPlans && financialPlans?.length > 0 && (
              <Swiper
                effect="coverflow"
                centeredSlides
                slidesPerView="auto"
                spaceBetween={100}
                autoplay={{
                  delay: AppSettings.SlidesAutoPlayDelay,
                  disableOnInteraction: true,
                }}
                coverflowEffect={{
                  rotate: 0,
                  stretch: 7,
                  depth: 60,
                  modifier: 3,
                  slideShadows: false,
                }}
                lazy
                loop
                pagination={{
                  clickable: true,
                  el: `.${classes.pagination}`,
                  bulletActiveClass: classes.bulletActive,
                  renderBullet: (_, className): string => {
                    return `<span class="${className} ${classes.bullet}"></span>`;
                  },
                }}
                className={classes.slideContainer}
                onSwiper={setSwiperInstance}
              >
                {financialPlans.map((fp) => {
                  return fp ? (
                    <SwiperSlide
                      style={{
                        backgroundImage: `url("${
                          fp.image_url ? fp.image_url : defaultPlanImage
                        }")`,
                      }}
                      className={classes.slide}
                      key={fp.plan_name || 0}
                      onClick={openDialog(fp)}
                    >
                      <div className={classes.planInfo}>
                        <div className={classes.planPrice}>
                          <span>
                            {numberWithCommas(fp.price)} {fp.currency_symbol}
                          </span>
                        </div>
                        <div className={classes.planTitleDescription}>
                          <h2 className={classes.planTitle}>{fp.plan_name}</h2>
                          <p className={classes.planDescription}>
                            {fp.description}
                          </p>
                        </div>
                      </div>
                    </SwiperSlide>
                  ) : (
                    <></>
                  );
                })}
              </Swiper>
            )}
            <div className={classes.pagination} />
          </Grid>
        </Grid>
      </Container>
      <MyPlansGuideDialog
        open={dialogIsOpen}
        onClose={closeDialog}
        trainerId={trainerId}
        financialPlan={selectedFinancialPlan}
      />
    </div>
  );
}
