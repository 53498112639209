import { useQuery } from "@apollo/react-hooks";
import {
  ConfigTypes,
  ValidLanguageCodes,
} from "@rambody/commons/lib/constants";
import { ITrainerPublicInfo } from "@rambody/db-schema/lib/user/IUser";
import { NetworkStatus } from "apollo-client";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import PageLoading from "../components/Common/PageLoading";
import TrainerProfile from "../components/TrainerProfile/TrainerProfile";
import { saveConfig } from "../extentions/config-provider";
import { GET_TRAINER_INFO } from "../queries/GetTrainerInfoQuery";
import { AppState } from "../store/state";
import NotFoundPage from "./NotFoundPage";

interface MatchParams {
  trainerId: string;
}

const TrainerProfileContainer: React.FC = () => {
  const { trainerId } = useParams<MatchParams>();
  const { userLanguage } = useSelector((state: AppState) => {
    return {
      userLanguage: state.userLanguage,
    };
  });
  const {
    data: queryResult,
    refetch,
    networkStatus,
  } = useQuery<
    { GetTrainerInfo: ITrainerPublicInfo },
    { rambodyId: string; langCode?: ValidLanguageCodes }
  >(GET_TRAINER_INFO, {
    variables: {
      rambodyId: trainerId,
      langCode: userLanguage.code as ValidLanguageCodes,
    },
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    if (refetch) {
      refetch();
    }
  }, [refetch, userLanguage]);

  useEffect(() => {
    async function saveIpLog(): Promise<void> {
      if (queryResult) {
        await saveConfig(ConfigTypes.IpLog, {
          trainerId: queryResult.GetTrainerInfo.morabix_id,
        });
      }
    }
    function changeSocialContents(): void {
      if (queryResult) {
        const titleTag = document.querySelector("title");
        if (titleTag) {
          titleTag.innerText = `Rambody Trainer Portal - ${
            queryResult.GetTrainerInfo.fullname || ""
          }`;
        }
        const ogTitleTag = document.querySelector('meta[property="og:title"]');
        if (ogTitleTag) {
          ogTitleTag.setAttribute(
            "content",
            queryResult.GetTrainerInfo.fullname || ""
          );
        }
        const twitterTitleTag = document.querySelector(
          'meta[name="twitter:title"]'
        );
        if (twitterTitleTag) {
          twitterTitleTag.setAttribute(
            "content",
            queryResult.GetTrainerInfo.fullname || ""
          );
        }
        const descriptionTag = document.querySelector(
          'meta[name="description"]'
        );
        if (descriptionTag) {
          descriptionTag.setAttribute(
            "content",
            queryResult.GetTrainerInfo.overview || ""
          );
        }
        const ogDescriptionTag = document.querySelector(
          'meta[property="og:description"]'
        );
        if (ogDescriptionTag) {
          ogDescriptionTag.setAttribute(
            "content",
            queryResult.GetTrainerInfo.overview || ""
          );
        }
        const twitterDescriptionTag = document.querySelector(
          'meta[name="twitter:description"]'
        );
        if (twitterDescriptionTag) {
          twitterDescriptionTag.setAttribute(
            "content",
            queryResult.GetTrainerInfo.overview || ""
          );
        }
        const ogImageTag = document.querySelector('meta[property="og:image"]');
        if (ogImageTag && queryResult.GetTrainerInfo.photo_url) {
          ogImageTag.setAttribute(
            "content",
            queryResult.GetTrainerInfo.photo_url
          );
        }
      }
    }
    saveIpLog();
    changeSocialContents();
  }, [queryResult]);

  if (networkStatus === NetworkStatus.loading) return <PageLoading />;

  if (!queryResult || !trainerId) return <NotFoundPage />;
  const { GetTrainerInfo: trainerInfo } = queryResult;
  return (
    <TrainerProfile
      trainerInfo={trainerInfo}
      trainerId={trainerId}
      loading={networkStatus === NetworkStatus.refetch}
    />
  );
};

export default TrainerProfileContainer;
